import { Fragment, useEffect, useRef, useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldDefaultFormulaIdentifier } from "../../fields/FieldDefaultFormulaIdentifier";
import { FieldTable } from "../../fields/FieldTable";
import { PanelProps } from "../ModalCreateColumn";

export const PanelCreateColumnIdentifier = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const [table, setTable] = useState<{
    id: string;
    kind: "data_table" | "gdt_analysis_table";
  }>();

  // Update column definition
  useEffect(() => {
    if (table !== undefined) {
      onFieldChange("definition", {
        value: {
          kind: "identifier",
          table,
        },
        isValid: true,
      });
    } else {
      onFieldChange("definition", {
        value: undefined,
        isValid: false,
      });
    }
  }, [onFieldChange, table]);

  /**
   * Shifts focus to the name field.
   *
   * This is tried multiple times because EuiSuperSelect (used by
   * FieldFileMetadatum) refocuses itself after onChange is called. I believe
   * this is a bug in EUI but I think this is the best option in the absence of
   * a long-term fix.
   */
  const focusNameField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== nameFieldRef.current) {
        nameFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  return (
    <Fragment>
      <FieldTable
        autoFocus
        value={table?.id}
        onChange={(value) => {
          // Update state
          setTable(value);

          // Reset default formula
          onFieldChange("default_formula", {
            value: undefined,
            isValid: true,
          });

          // Auto-fill name field if not previously edited
          if (value !== undefined && !fields.name.isDirty) {
            onFieldChange("name", {
              value: value.name,
              isValid: true,
              isDirty: false,
            });
          }

          // Shift focus to name field
          focusNameField();
        }}
      />

      <FieldColumnName
        ref={nameFieldRef}
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />

      <FieldDefaultFormulaIdentifier
        tableId={table?.id}
        value={fields.default_formula.value}
        onChange={(value) => {
          onFieldChange("default_formula", {
            value: value,
            isValid: true,
          });
        }}
      />
    </Fragment>
  );
};
