import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { useState } from "react";

interface FieldTableProps {
  autoFocus?: boolean;
  label?: string;
  value: string | undefined;
  onChange: (
    value:
      | {
          id: string;
          key: string;
          name: string;
          kind: "data_table" | "gdt_analysis_table";
        }
      | undefined,
  ) => void;
}

export const FieldTable = ({
  autoFocus = false,
  label = "Table",
  value,
  onChange,
}: FieldTableProps) => {
  const tables = useDataTableContext((s) => s.tables);
  const [isOpen, setIsOpen] = useState(autoFocus);

  const options: EuiSuperSelectOption<string>[] = tables.map((table) => ({
    value: table.id,
    inputDisplay: (
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiIcon
            type={
              table.kind === "analysis" ? "indexMapping" : "tableDensityNormal"
            }
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <span>
            {table.name} <span style={{ color: "grey" }}>({table.key})</span>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
  }));

  const handleChange = (tableId: string) => {
    const table = tables.find((table) => table.id === tableId);
    if (table === undefined || table?.id === value) {
      onChange(undefined);
    } else {
      onChange({
        id: table.id,
        key: table.key,
        name: table.name,
        kind: table.kind === "data" ? "data_table" : "gdt_analysis_table",
      });
    }
  };

  return (
    <EuiFormRow label={label}>
      <EuiSuperSelect
        autoFocus={autoFocus}
        isOpen={isOpen}
        options={options}
        valueOfSelected={value}
        onChange={(value) => {
          handleChange(value);
          setIsOpen(false);
        }}
        popoverProps={{ closePopover: () => setIsOpen(false) }}
        placeholder="Select a table..."
      />
    </EuiFormRow>
  );
};
