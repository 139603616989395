import { Fragment, useEffect, useRef, useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldColumn, FieldColumnProps } from "../../fields/FieldColumn";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { PanelProps } from "../ModalCreateColumn";
import { isDefined } from "utils/isDefined";
import { getColumnAddress } from "pages/gdt/column-headers/ColumnHeaderBase";

type ColumnState = Parameters<FieldColumnProps["onChange"]>[0];

export const PanelCreateColumnJoin = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const rightColumnFieldRef = useRef<HTMLButtonElement>(null);
  const sourceColumnFieldRef = useRef<HTMLButtonElement>(null);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const [leftColumn, setLeftColumn] = useState<ColumnState>();
  const [rightColumn, setRightColumn] = useState<ColumnState>();
  const [sourceColumn, setSourceColumn] = useState<ColumnState>();

  // Update column definition and default formula
  useEffect(() => {
    if (
      isDefined(leftColumn) &&
      isDefined(rightColumn) &&
      isDefined(sourceColumn)
    ) {
      onFieldChange("definition", {
        value: {
          kind: "join",
          left_column: leftColumn,
          right_column: rightColumn,
          source_column: sourceColumn,
        },
        isValid: true,
      });

      const leftAddress = `${leftColumn.table.key}!${getColumnAddress(
        leftColumn.order,
      )}:${getColumnAddress(leftColumn.order)}`;

      const rightAddress = `${rightColumn.table.key}!${getColumnAddress(
        rightColumn.order,
      )}:${getColumnAddress(rightColumn.order)}`;

      const sourceAddress = `${sourceColumn.table.key}!${getColumnAddress(
        sourceColumn.order,
      )}:${getColumnAddress(sourceColumn.order)}`;

      onFieldChange("default_formula", {
        value: `=JOIN(${leftAddress}, ${rightAddress}, ${sourceAddress})`,
        isValid: true,
      });
    } else {
      onFieldChange("definition", {
        value: undefined,
        isValid: false,
      });

      onFieldChange("default_formula", {
        value: undefined,
        isValid: true,
      });
    }
  }, [leftColumn, onFieldChange, rightColumn, sourceColumn]);

  /**
   * Shifts focus to the name field.
   *
   * This is tried multiple times because EuiSuperSelect (used by
   * FieldFileMetadatum) refocuses itself after onChange is called. I believe
   * this is a bug in EUI but I think this is the best option in the absence of
   * a long-term fix.
   */
  const focusNameField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== nameFieldRef.current) {
        nameFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  return (
    <Fragment>
      <FieldColumn
        autoFocus
        filter={{ tableId: selectedTableId }}
        label="Left column"
        helpText="Compared against values in the right column."
        value={leftColumn?.id}
        onChange={(value) => {
          // Update state
          setLeftColumn(value);

          // Shift focus to right column field
          rightColumnFieldRef.current?.click();
        }}
      />

      <FieldColumn
        ref={rightColumnFieldRef}
        label="Right column"
        helpText="Compared against values in the left column."
        value={rightColumn?.id}
        onChange={(value) => {
          // Update state
          setRightColumn(value);
          setSourceColumn(undefined);

          // Shift focus to source column field
          setTimeout(() => {
            sourceColumnFieldRef.current?.click();
          }, 100);
        }}
      />

      <FieldColumn
        ref={sourceColumnFieldRef}
        disabled={rightColumn === undefined}
        filter={{ tableId: rightColumn?.table.id }}
        label="Source column"
        helpText="Display rows from this column when values in the left and right columns match."
        value={sourceColumn?.id}
        onChange={(value) => {
          // Update state
          setSourceColumn(value);

          // Auto-fill name field if not previously edited
          if (!fields.name.isDirty) {
            onFieldChange("name", {
              value: value.name,
              isValid: true,
              isDirty: false,
            });
          }

          // Shift focus to name field
          focusNameField();
        }}
      />

      <FieldColumnName
        ref={nameFieldRef}
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />
    </Fragment>
  );
};
