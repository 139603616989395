import { ButtonIconPermissioned } from "components/ButtonIconPermissioned/ButtonIconPermissioned";
import { ModalDeleteDrsFileProps } from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import { useRoutes } from "hooks/useRoutes";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";

export interface ButtonDeleteDrsFileProps {
  drsFile: ModalDeleteDrsFileProps["drsFiles"][number];
  onClick: (file: ButtonDeleteDrsFileProps["drsFile"]) => void;
}

export const ButtonDeleteDrsFile = ({
  drsFile,
  onClick,
}: ButtonDeleteDrsFileProps) => {
  const { routeMap } = useRoutes();
  const isViewingHistory =
    routeMap.DATASET_HISTORY.isRouteMatch() ||
    routeMap.DATASET_VERSION.isRouteMatch();

  return (
    <ButtonIconPermissioned
      aria-label="Delete file"
      defaultTooltip={
        isViewingHistory
          ? "Delete disabled while viewing history"
          : "Delete file"
      }
      color="danger"
      iconType="trash"
      disabled={
        isViewingHistory ||
        !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DELETE")
          .isPermitted ||
        !getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DATA_DELETE")
          .isPermitted
      }
      onClick={() => onClick(drsFile)}
      requiredPermission="edit"
    />
  );
};
