import { Fragment, useEffect } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldDefaultFormulaBoolean } from "../../fields/FieldDefaultFormulaBoolean";
import { PanelProps } from "../ModalCreateColumn";

export const PanelCreateColumnBoolean = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  // Update column definition
  useEffect(() => {
    onFieldChange("definition", {
      value: {
        kind: "boolean",
      },
      isValid: true,
    });
  }, [onFieldChange]);

  return (
    <Fragment>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />

      <FieldDefaultFormulaBoolean
        value={fields.default_formula.value}
        onChange={(field) => {
          onFieldChange("default_formula", {
            value: field.value,
            isValid: field.isValid,
          });
        }}
      />
    </Fragment>
  );
};
