import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiComboBoxProps,
  EuiFormRow,
} from "@inscopix/ideas-eui";
import { useMemo } from "react";
import { FILE_TYPES_BY_ID, FileType } from "types/FileTypes";
import { metadataMap, UNSELECTABLE_METADATA_KEYS } from "types/MetadataMap";
import { isDefined } from "utils/isDefined";

interface FieldFileMetadatumProps {
  autoFocus?: boolean;
  disabled?: boolean;
  inputRef: EuiComboBoxProps<string>["inputRef"];
  fileType: FileType["id"] | undefined;
  value: string | undefined;
  onChange: (value: { key: string; name: string } | undefined) => void;
}

export const FieldFileMetadatum = ({
  autoFocus,
  disabled = false,
  inputRef,
  fileType,
  value,
  onChange,
}: FieldFileMetadatumProps) => {
  const options: EuiComboBoxOptionOption<string>[] = useMemo(() => {
    return isDefined(fileType)
      ? Object.entries(metadataMap[FILE_TYPES_BY_ID[fileType].key] ?? {})
          .map(([key, value]) => ({
            value: key,
            label: value,
          }))
          // TODO: ID-2041 remove filter once DLC movies have their own type
          .filter(
            (option) => !UNSELECTABLE_METADATA_KEYS.includes(option.value),
          )
      : [];
  }, [fileType]);

  const handleChange = (options: EuiComboBoxOptionOption<string>[]) => {
    const selectedOption = options[0];
    if (isDefined(selectedOption)) {
      onChange({
        key: selectedOption.value as string,
        name: selectedOption.label,
      });
    } else {
      onChange(undefined);
    }
  };

  const selectedOptions = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return isDefined(option) ? [option] : [];
  }, [options, value]);

  return (
    <EuiFormRow
      label="Metadatum"
      helpText="Choose the metadatum value you wish to display."
    >
      <EuiComboBox<string>
        inputRef={inputRef}
        singleSelection={{ asPlainText: true }}
        isDisabled={disabled}
        options={options}
        selectedOptions={selectedOptions}
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder="Select a metadatum value..."
        rowHeight={40}
      />
    </EuiFormRow>
  );
};
