/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiToken,
} from "@inscopix/ideas-eui";
import { clamp } from "lodash";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";
import { createRef, Fragment, KeyboardEvent, useState } from "react";
import { COLUMN_KIND_MAP } from "../ModalCreateColumn/ModalCreateColumn";

interface FieldColumnTypeProps {
  autoFocus?: boolean;
  value: DataTableColumnDefinition["kind"] | undefined;
  onChange: (value: DataTableColumnDefinition["kind"] | undefined) => void;
}

export const FieldColumnType = ({
  autoFocus,
  value,
  onChange,
}: FieldColumnTypeProps) => {
  const [refs] = useState(() => {
    return Object.keys(COLUMN_KIND_MAP).map(() =>
      createRef<HTMLAnchorElement | HTMLButtonElement>(),
    );
  });

  /**
   * Shifts the focus to the next column type using the up and down arrow keys.
   * @param e
   */
  const handleKeyDown = (
    e: KeyboardEvent<HTMLDivElement | HTMLSpanElement>,
  ) => {
    const focusRefIdx = refs.findIndex(
      (ref) => document.activeElement === ref.current,
    );

    if (focusRefIdx !== -1) {
      if (e.key === "ArrowUp") {
        const nextFocusRefIdx = clamp(focusRefIdx - 1, 0, refs.length - 1);
        refs[nextFocusRefIdx].current?.focus();
      }

      if (e.key === "ArrowDown") {
        const nextFocusedRefIndex = clamp(focusRefIdx + 1, 0, refs.length - 1);
        refs[nextFocusedRefIndex].current?.focus();
      }
    }
  };

  if (value === undefined) {
    return (
      <EuiFormRow label="Column type">
        <EuiFlexGroup
          direction="column"
          gutterSize="xs"
          onKeyDown={handleKeyDown}
        >
          {Object.entries(COLUMN_KIND_MAP)
            // TODO: Add interfaces for these column types when we have time
            .filter(
              ([_, { name }]) => name !== "Choice" && name !== "ROI Frame",
            )
            .map(([id, kind], idx, arr) => {
              const isLastItem = idx === arr.length - 1;
              return (
                <Fragment key={id}>
                  <EuiButtonEmpty
                    buttonRef={refs[idx]}
                    autoFocus={autoFocus ? idx === 0 : undefined}
                    color="text"
                    css={css`
                      &:focus {
                        background-color: #e6f1fa;
                      }
                    `}
                    textProps={{
                      css: css`
                        width: 100%;
                        text-align: left;
                      `,
                    }}
                    onClick={() =>
                      onChange(id as DataTableColumnDefinition["kind"])
                    }
                  >
                    <EuiFlexGroup
                      alignItems="center"
                      gutterSize="s"
                      responsive={false}
                    >
                      <EuiFlexItem grow={false}>
                        <EuiToken iconType={kind.icon} />
                      </EuiFlexItem>

                      <EuiFlexItem style={{ fontWeight: "normal" }}>
                        {kind.name}
                      </EuiFlexItem>

                      <EuiFlexItem grow={false}>
                        <EuiIcon color="subdued" type="arrowRight" />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiButtonEmpty>

                  {!isLastItem && <EuiHorizontalRule margin="none" />}
                </Fragment>
              );
            })}
        </EuiFlexGroup>
      </EuiFormRow>
    );
  }

  return (
    <EuiFormRow label="Column type">
      <EuiButtonEmpty
        autoFocus={autoFocus}
        color="text"
        style={{ border: "1px solid #e3e6f1", width: "100%" }}
        textProps={{
          style: { width: "100%", textAlign: "left" },
        }}
        onClick={() => onChange(undefined)}
      >
        <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiIcon color="subdued" type="arrowLeft" />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiToken iconType={COLUMN_KIND_MAP[value].icon} />
          </EuiFlexItem>

          <EuiFlexItem style={{ fontWeight: "normal" }}>
            {COLUMN_KIND_MAP[value].name}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiButtonEmpty>
    </EuiFormRow>
  );
};
