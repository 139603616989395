import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiLink,
  EuiTableComputedColumnType,
} from "@inscopix/ideas-eui";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { gql, useFragment } from "@apollo/client";
import { useRoutes } from "hooks/useRoutes";
import {
  File as DrsFile,
  PopoverFileUploadFragment,
  PopoverFileUploadFragmentDoc,
} from "graphql/_Types";
import { FileBadge } from "components/FileBadge/FileBadge";
import usePagination from "hooks/usePagination";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { FileNotFoundBadge } from "components/FileBadge/FileNotFoundBadge";
import { captureException } from "@sentry/react";
import { useUserContext } from "providers/UserProvider/UserProvider";

export const PopoverFileUploadContent = () => {
  const { routeMap } = useRoutes();
  const tenants = useUserContext((s) => s.tenants);

  const files = useFileUploadContext((s) => {
    const entries = Object.entries(s.filesById);
    return entries.map(([drsFileId, attrs]) => {
      assert(isDefined(attrs));
      const { project } = attrs;
      return { drsFileId, project };
    });
  });

  const pageSize = 5;
  const { activePage, onPageChange, activePageItems } = usePagination({
    allItems: files,
    itemsPerPage: pageSize,
  });

  const columns: EuiTableComputedColumnType<(typeof files)[number]>[] = [
    {
      name: "File",
      render: (file) => {
        const { drsFileId } = file;
        return <CellRendererFile drsFileId={drsFileId} />;
      },
    },
    {
      name: "Project",
      render: (file) => {
        const tenant = tenants.find(
          (tenant) => tenant.id === file.project.tenantId,
        );
        assert(isDefined(tenant));
        const projectKey = file.project.key;
        const { path } = routeMap.PROJECT.dynamicPath({
          tenantKey: tenant.key,
          projectKey,
        });
        return <EuiLink to={path}>{file.project.name}</EuiLink>;
      },
    },
  ];

  return (
    <EuiBasicTable
      columns={columns}
      items={activePageItems}
      onChange={(criteria: CriteriaWithPagination<(typeof files)[number]>) => {
        onPageChange(criteria.page.index);
      }}
      pagination={{
        pageIndex: activePage,
        pageSize,
        totalItemCount: files.length,
        showPerPageOptions: false,
      }}
    />
  );
};

gql`
  fragment PopoverFileUpload on File {
    id
    name
    status
    fileType
    source
    processingStatus
    seriesParentId
  }
`;

interface CellRendererFileProps {
  drsFileId: DrsFile["id"];
}

const CellRendererFile = ({ drsFileId }: CellRendererFileProps) => {
  const result = useFragment<PopoverFileUploadFragment>({
    fragment: PopoverFileUploadFragmentDoc,
    from: `File:${drsFileId}`,
  });

  if (!result.complete) {
    captureException("Received cache miss when reading DrsFile cache");
    return <FileNotFoundBadge />;
  }

  return (
    <FileBadge
      drsFile={{
        ...result.data,
        isSeries: false,
      }}
    />
  );
};
