import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiText,
} from "@inscopix/ideas-eui";
import { FlyoutDrsFileInfoQuery, File as DrsFile } from "graphql/_Types";
import { useState } from "react";
import {
  FlyoutFileInfoBody,
  FlyoutFileInfoBodyProps,
} from "./FlyoutFileInfoBody";
import { CallOutError } from "components/CallOutError/CallOutError";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { ButtonDownloadFile } from "components/ButtonDownloadFile/ButtonDownloadFile";

interface FlyoutFileInfoSeriesBodyProps
  extends Pick<
    FlyoutFileInfoBodyProps,
    "assignments" | "drsFileAnalysisTableInfo"
  > {
  activeFile: NonNullable<FlyoutDrsFileInfoQuery["drsFile"]>;
}

export const FlyoutFileInfoSeriesBody = ({
  activeFile,
  ...flyoutFileInfoBodyProps
}: FlyoutFileInfoSeriesBodyProps) => {
  const [selectedSeriesFileId, setSelectedSeriesFileId] = useState<
    DrsFile["id"]
  >(activeFile.seriesFiles.nodes[0].id);

  const selectOptions = activeFile.seriesFiles.nodes.map((file) => ({
    text: file.name,
    value: file.id,
  }));

  const selectedSeriesFile = activeFile.seriesFiles.nodes.find(
    ({ id }) => id === selectedSeriesFileId,
  );

  if (selectedSeriesFile === undefined) {
    return <CallOutError />;
  }

  const isDownloadPermitted = getDrsFileModifyPermissionByDrsFileAndAction(
    selectedSeriesFile,
    "DOWNLOAD",
  ).isPermitted;

  return (
    <>
      <EuiFlexGroup alignItems={"center"}>
        <EuiFlexItem grow={false}>
          <EuiText>
            <h5>Select series file:</h5>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow>
          <EuiSelect
            fullWidth
            options={selectOptions}
            value={selectedSeriesFileId}
            onChange={(e) => setSelectedSeriesFileId(e.target.value)}
            aria-label="Select file from series to display"
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {isDownloadPermitted && (
            <ButtonDownloadFile file={selectedSeriesFile} />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <FlyoutFileInfoBody
        activeFile={selectedSeriesFile}
        {...flyoutFileInfoBodyProps}
      />
    </>
  );
};
