import { createContext, ReactNode, useContext, useState } from "react";
import {
  createFileUploadStore,
  FileUploadStoreState,
} from "./FileUploadProvider.helpers";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { useStore } from "zustand";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { useUploadMultiPartFile } from "hooks/useUploadMultipartFile/useUploadMultiPartFile";
import { FileUploadQueue } from "./FileUploadQueue";

/** Represents the file upload store object */
type FileUploadStore = ReturnType<typeof createFileUploadStore>;

/** Context storing the file upload store */
const FileUploadContext = createContext<FileUploadStore | undefined>(undefined);

interface FileUploadProviderProps {
  children: ReactNode;
}

/** Context provider for managing data for global file uploads */
export const FileUploadProvider = ({ children }: FileUploadProviderProps) => {
  const { uploadMultiPartFile, cancelFileUpload } = useUploadMultiPartFile();
  const [store] = useState(createFileUploadStore({ cancelFileUpload }));

  return (
    <FileUploadContext.Provider value={store}>
      <FileUploadQueue uploadMultiPartFile={uploadMultiPartFile}>
        {children}
      </FileUploadQueue>
    </FileUploadContext.Provider>
  );
};

/** Hook for consuming the {@link FileUploadContext} */
export const useFileUploadContext = <T,>(
  selector: (state: FileUploadStoreState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(FileUploadContext);
  assert(isDefined(store), new ContextOutOfBoundsError("FileUploadContext"));
  return useStore(store, selector, equalityFn);
};
