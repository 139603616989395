import { EuiResizableContainer } from "@inscopix/ideas-eui";
import { useState } from "react";
import { DataTable } from "./DataTable";
import { DataTableFlyoutRight } from "./DataTableFlyoutRight";
import { useResizablePanels } from "components/Project/useResizablePanels";

export const DataTableContent = () => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const { panels, handlePanelWidthChange } = useResizablePanels({
    isBottomFlyoutOpen: false,
    isRightFlyoutOpen: isFlyoutOpen,
  });

  return (
    <EuiResizableContainer
      direction="horizontal"
      onPanelWidthChange={handlePanelWidthChange}
      style={{ width: "100%", height: "100%" }}
    >
      {(EuiResizablePanel, EuiResizableButton) => (
        <>
          <EuiResizablePanel
            id={panels.MAIN_AND_FLYOUT_BOTTOM.id}
            panelRef={panels.MAIN_AND_FLYOUT_BOTTOM.ref}
            size={panels.MAIN_AND_FLYOUT_BOTTOM.size}
            paddingSize="none"
          >
            <DataTable />
          </EuiResizablePanel>

          <EuiResizableButton
            indicator="border"
            style={{ display: isFlyoutOpen ? undefined : "none" }}
          />

          <EuiResizablePanel
            id={panels.FLYOUT_RIGHT.id}
            panelRef={panels.FLYOUT_RIGHT.ref}
            size={panels.FLYOUT_RIGHT.size}
            paddingSize="none"
            minSize="300px"
          >
            <DataTableFlyoutRight toggleFlyout={setIsFlyoutOpen} />
          </EuiResizablePanel>
        </>
      )}
    </EuiResizableContainer>
  );
};
