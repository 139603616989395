// Override the default context menu items for AG-Grid to add a custom export as CSV option.
import { ToolParamsGridRowDatum } from "../ToolParamsGrid.types";
import { RefObject, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { exportAsCSV } from "utils/exportAsCSV";
import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { keyBy } from "lodash";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";

export const useToolParamsGridsGetContextMenuItems = <
  TData extends ToolParamsGridRowDatum,
>(
  gridRef: RefObject<AgGridReact<TData>>,
  exportName?: string,
) => {
  const { toolSpec } = useToolParamsGridContext();
  const projectFiles = useProjectFilesStore((s) => s.files);
  return useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        "copy",
        "copyWithHeaders",
        "copyWithGroupHeaders",
        "paste",
        "separator",
        {
          name: "Export as CSV",
          action: () =>
            exportAsCSV(
              gridRef,
              exportName,
              keyBy(projectFiles, ({ id }) => id),
              toolSpec,
            ),
          icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
        },
      ];
    },
    [gridRef, exportName, projectFiles, toolSpec],
  );
};
