import { Fragment, useState } from "react";
import {
  EuiCheckableCard,
  EuiCheckbox,
  EuiConfirmModal,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { useDrsFileScheduleDeleteDjango } from "hooks/useDrsFileScheduleDeleteDjango";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import assert from "assert";
import { cloneDeep } from "lodash";
import { isNonNull } from "utils/isNonNull";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { ModalSyncingDataset } from "./ModalSyncingDataset";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { useDrsFileScheduleDataDeleteDjango } from "hooks/useDrsFileScheduleDataDeleteDjango";
import { isDefined } from "utils/isDefined";
import { evictCacheFragment, updateCacheFragment } from "utils/cache-fragments";
import { captureException } from "@sentry/react";
import { useActionQueueStore } from "stores/ActionsQueue/ActionsQueueManager";
import { useSelectedDrsFileId } from "stores/useSelectedDrsFileId";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";

type ModalOptions = "DATA_DELETE" | "DELETE";
type ModalOptionLabelMap = Record<ModalOptions, string>;
type ModalOptionsBodyMap = Record<ModalOptions, React.ReactNode>;

const modalOptionLabelMap: ModalOptionLabelMap = {
  DATA_DELETE: "Delete Data",
  DELETE: "Delete Data and History",
} as const;

export type ModalDeleteDrsFileProps = {
  drsFiles: Pick<
    DrsFile,
    | "id"
    | "status"
    | "name"
    | "source"
    | "isSeries"
    | "projectId"
    | "seriesParentId"
    | "processingStatus"
  >[];
  onClose: () => void;
  onComplete?: ({
    successfulFiles,
    errors,
  }: {
    successfulFiles: Pick<DrsFile, "id">[];
    errors: Error[];
  }) => void;
};

export const ModalDeleteDrsFile = ({
  drsFiles,
  onClose,
  onComplete,
}: ModalDeleteDrsFileProps) => {
  const deselectDrsFile = useSelectedDrsFileId((s) => s.deselectDrsFile);
  const cancelFileUploads = useFileUploadContext((s) => s.cancelFileUploads);

  const projectId = drsFiles[0].projectId;
  assert(isNonNull(projectId));
  const { drsFileScheduleDelete } = useDrsFileScheduleDeleteDjango();
  const { drsFileScheduleDataDelete } = useDrsFileScheduleDataDeleteDjango();

  const dataDeletableDrsFiles = drsFiles.filter(
    (drsFile) =>
      getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DATA_DELETE")
        .isPermitted,
  );

  const hardDeletableDrsFiles = drsFiles.filter(
    (drsFile) =>
      getDrsFileModifyPermissionByDrsFileAndAction(drsFile, "DELETE")
        .isPermitted,
  );

  assert(
    hardDeletableDrsFiles.length > 0 || dataDeletableDrsFiles.length > 0,
    "Expected at least one deletable drsFile in ModalDeleteDrsFile",
  );

  const availableOptions: { default: ModalOptions; available: ModalOptions[] } =
    (() => {
      const available: ModalOptions[] = [];
      if (dataDeletableDrsFiles.length > 0) {
        available.push("DATA_DELETE");
      }
      if (hardDeletableDrsFiles.length > 0) {
        available.push("DELETE");
      }
      return {
        available,
        default: available.some(
          (availableOption) => availableOption === "DATA_DELETE",
        )
          ? "DATA_DELETE"
          : "DELETE",
      };
    })();

  const [checked, setChecked] = useState<ModalOptions>(
    availableOptions.default,
  );

  const status = useActionQueueStore((s) => s.status);
  const areActionsSynced = status === "synced";

  const [isConfirmDeleteChecked, setIsConfirmDeleteChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const hardDeleteFiles = async (drsFiles: Pick<DrsFile, "id">[]) => {
    const hardDeleteDrsFilesFromCache = (drsFiles: Pick<DrsFile, "id">[]) => {
      const fileIds = drsFiles.map((file) => file.id);
      cancelFileUploads(fileIds);

      drsFiles.forEach((drsFile) =>
        evictCacheFragment({ __typename: "File", id: drsFile.id }),
      );
    };

    const dateDeleted = new Date().toISOString();
    const requests = drsFiles.map(async ({ id }) => {
      try {
        return await drsFileScheduleDelete(id, {
          date_deleted: dateDeleted,
        });
      } catch (err) {
        return new Error(`Failed to delete drsFile ${id}`);
      }
    });

    const drsFilesDeletedResults = await Promise.all(requests);

    const successfullyDeletedFiles = drsFilesDeletedResults.filter(
      function (
        objectOrError,
      ): objectOrError is Awaited<ReturnType<typeof drsFileScheduleDelete>> {
        return !(objectOrError instanceof Error);
      },
    );

    const errors = drsFilesDeletedResults.filter(
      function (result): result is Error {
        return result instanceof Error;
      },
    );

    if (errors.length > 0) {
      addUtilityToastFailure(
        `Failed to delete ${errors.length} out of ${drsFilesDeletedResults.length} files.`,
      );
    } else {
      addUtilityToastSuccess(`Successfully deleted ${requests.length} files.`);
    }

    hardDeleteDrsFilesFromCache(successfullyDeletedFiles);
    if (isDefined(onComplete)) {
      onComplete({ successfulFiles: successfullyDeletedFiles, errors });
    }
    return { successfulFiles: successfullyDeletedFiles };
  };

  const dataDeleteFiles = async (drsFiles: Pick<DrsFile, "id">[]) => {
    const dataDeleteDrsFilesInCache = (drsFiles: Pick<DrsFile, "id">[]) => {
      updateCacheFragment({
        __typename: "Project",
        id: projectId,
        update: (data) => {
          const newData = cloneDeep(data);

          if (newData.activeFiles === undefined) {
            newData.activeFiles = {
              __typename: "FilesConnection",
              nodes: [],
            };
            captureException("Missing files connection in project cache");
          } else {
            const drsFileIds = new Set(drsFiles.map(({ id }) => id));
            newData.activeFiles.nodes = newData.activeFiles.nodes.filter(
              ({ id }) => !drsFileIds.has(id),
            );
          }

          return newData;
        },
      });
    };

    const dateDataDeleted = new Date().toISOString();
    const requests = drsFiles.map(async ({ id }) => {
      try {
        return await drsFileScheduleDataDelete(id, {
          date_data_deleted: dateDataDeleted,
        });
      } catch (err) {
        return new Error(`Failed to delete drsFile ${id}`);
      }
    });

    const drsFilesDeletedResults = await Promise.all(requests);

    const successfullyDataDeletedFiles = drsFilesDeletedResults.filter(
      function (
        objectOrError,
      ): objectOrError is Awaited<
        ReturnType<typeof drsFileScheduleDataDelete>
      > {
        return !(objectOrError instanceof Error);
      },
    );

    const errors = drsFilesDeletedResults.filter(
      function (result): result is Error {
        return result instanceof Error;
      },
    );

    if (errors.length > 0) {
      addUtilityToastFailure(
        `Failed to delete ${errors.length} out of ${drsFilesDeletedResults.length} files.`,
      );
    } else {
      addUtilityToastSuccess(`Successfully deleted ${requests.length} files.`);
    }

    dataDeleteDrsFilesInCache(successfullyDataDeletedFiles);
    if (isDefined(onComplete)) {
      onComplete({ successfulFiles: successfullyDataDeletedFiles, errors });
    }
    return { successfulFiles: successfullyDataDeletedFiles };
  };

  const onSubmit = async () => {
    setIsLoading(true);
    switch (checked) {
      case "DELETE":
        {
          const hardDeletedFiles = await hardDeleteFiles(hardDeletableDrsFiles);
          hardDeletedFiles.successfulFiles.forEach((file) =>
            deselectDrsFile(file.id),
          );
        }
        break;
      case "DATA_DELETE":
        {
          const dataDeletedFiles = await dataDeleteFiles(dataDeletableDrsFiles);
          dataDeletedFiles.successfulFiles.forEach((file) =>
            deselectDrsFile(file.id),
          );
        }
        break;
    }
    setIsLoading(false);
    onClose();
  };

  const modalOptionsBodyMap: ModalOptionsBodyMap = {
    DATA_DELETE: (
      <EuiText>
        Remove this data from storage. Metadata, previews, and task/dataset
        history will be preserved, but the file will become permanently
        unavailable and will not count towards active or archived storage.
      </EuiText>
    ),
    DELETE: (
      <>
        <EuiText>
          <p>
            Completely remove the file and any associated metadata, previews,
            and history. This is only recommended in cases of sensitive data
            that must be fully removed.
          </p>
          <p>
            <strong>
              This will retroactively remove this data from any saved dataset
              versions and existing analyses.
            </strong>
          </p>
        </EuiText>
        <EuiSpacer size="l" />
        <EuiCheckbox
          id="CONFIRM_DELETE"
          label={
            <EuiText>
              <strong>
                I understand this action will alter historical records and
                cannot be undone.
              </strong>
            </EuiText>
          }
          checked={isConfirmDeleteChecked}
          onChange={(e) => setIsConfirmDeleteChecked(e.target.checked)}
          disabled={checked !== "DELETE"}
        />
      </>
    ),
  };

  if (!areActionsSynced) {
    return <ModalSyncingDataset onClose={onClose} />;
  }

  return (
    <EuiConfirmModal
      title="Delete File"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      onCancel={onClose}
      onConfirm={() => void onSubmit()}
      buttonColor="danger"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={
        !areActionsSynced || (checked === "DELETE" && !isConfirmDeleteChecked)
      }
      isLoading={isLoading}
    >
      <>
        {availableOptions.available.map((option, i, arr) => (
          <Fragment key={option}>
            <EuiCheckableCard
              label={modalOptionLabelMap[option]}
              id={option}
              checked={checked === option}
              onChange={() => {
                setIsConfirmDeleteChecked(false);
                setChecked(option);
              }}
              hasShadow={false}
            >
              {checked === option ? modalOptionsBodyMap[option] : null}
            </EuiCheckableCard>
            {i !== arr.length - 1 && <EuiSpacer size="s" />}
          </Fragment>
        ))}
      </>
    </EuiConfirmModal>
  );
};
