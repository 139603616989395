import { Fragment, useEffect } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldDefaultFormulaInteger } from "../../fields/FieldDefaultFormulaInteger";
import { PanelProps } from "../ModalCreateColumn";

export const PanelCreateColumnInteger = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  // Update column definition
  useEffect(() => {
    onFieldChange("definition", {
      value: {
        kind: "integer",
        min: null,
        max: null,
      },
      isValid: true,
    });
  }, [onFieldChange]);

  return (
    <Fragment>
      <FieldColumnName
        autoFocus
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />

      <FieldDefaultFormulaInteger
        value={fields.default_formula.value}
        onChange={(value) => {
          onFieldChange("default_formula", {
            value: value,
            isValid: value !== undefined && parseInt(value) === Number(value),
          });
        }}
      />
    </Fragment>
  );
};
