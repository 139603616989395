import GraphQlProvider from "./ApolloProvider/ApolloProvider";
import { FeatureFlagsProvider } from "./FeatureFlagsProvider";
import { ThemeProvider } from "./ThemeProvider";
import { SentryErrorBoundary } from "../components/SentryErrorBoundary/SentryErrorBoundary";
import {
  Button,
  Divider,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import assert from "assert";
import { EuiImage } from "@inscopix/ideas-eui";
import Inscopix_logo from "assets/Inscopix-logo.svg";
import { BackendStatusManager } from "./BackendStatusManager/BackendStatusManager";
import { Auth } from "aws-amplify";
import { RegionsProvider } from "./RegionsProvider";
import { UserProvider } from "./UserProvider/UserProvider";
import { FileUploadProvider } from "stores/upload/FileUploadProvider";
import { ModalProvider } from "./ModalProvider/ModalProvider";

const IDP = process.env.REACT_APP_AWS_AUTH_SSO_IDP;
const IDP_NAME = process.env.REACT_APP_AWS_AUTH_SSO_IDP_NAME ?? "SSO";

const authenticatorComponents = {
  Header() {
    return (
      <View textAlign="center">
        <EuiImage
          size="l"
          alt="Inscopix logo"
          aria-label="Inscopix logo"
          url={Inscopix_logo}
          style={{ padding: "20px" }}
        />
        {IDP !== undefined && (
          <Button
            variation="primary"
            size="large"
            isFullWidth={true}
            onClick={() => void Auth.federatedSignIn({ customProvider: IDP })}
          >
            Sign in with {IDP_NAME}
          </Button>
        )}
        {IDP !== undefined && (
          <div style={{ margin: "30px" }}>
            <Divider label="OR" />
          </div>
        )}
      </View>
    );
  },
};

const authenticatorFormFields = {
  signIn: {
    username: {
      placeholder: "Email",
      isRequired: true,
      labelHidden: true,
    },
    password: {
      placeholder: "Password",
      isRequired: true,
      labelHidden: true,
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email",
      isRequired: true,
      labelHidden: true,
    },
  },
};

const GlobalProviders = (props?: { children: React.ReactNode }) => {
  assert(
    props !== undefined,
    "Expected Global Providers to have defined props",
  );

  return (
    <SentryErrorBoundary tag={"providers"}>
      <ThemeProvider>
        <FeatureFlagsProvider>
          <RegionsProvider>
            <BackendStatusManager>
              <GraphQlProvider>
                <UserProvider>
                  <FileUploadProvider>
                    <ModalProvider>{props.children}</ModalProvider>
                  </FileUploadProvider>
                </UserProvider>
              </GraphQlProvider>
            </BackendStatusManager>
          </RegionsProvider>
        </FeatureFlagsProvider>
      </ThemeProvider>
    </SentryErrorBoundary>
  );
};

export default withAuthenticator(GlobalProviders, {
  components: authenticatorComponents,
  formFields: authenticatorFormFields,
});
