import {
  AnalysisTable,
  AnalysisTableGroup,
  Project,
  useGetAnalysisTableGroupUsedCreditsLazyQuery,
} from "graphql/_Types";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { cloneDeep, debounce, groupBy, uniq } from "lodash";
import { useUpdatePageProjectCache } from "pages/project/hooks/useUpdatePageProjectCache";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ToolParamsGridRowDatum as RowDatum } from "./ToolParamsGrid.types";
import { evictCacheFragment, updateCacheFragment } from "utils/cache-fragments";
import { captureException } from "@sentry/react";
import { syncMetadatumReferences } from "./ToolParamsGridProvider.helpers";
import { useOnlineStatus } from "hooks/useOnlineStatus";
import { libraryAnalysisTableRowDjango } from "django/libraryAnalysisTableRowDjango";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";

export type SaveStatus = "unsaved" | "saved" | "saving" | "error";

type TUnsavedRow = {
  new: boolean;
  row: RowDatum;
  saveError: boolean;
  delete?: boolean;
};

interface TablePersisterHookProps {
  tableId: AnalysisTable["id"];
  groupId: AnalysisTableGroup["id"];
  projectId: Project["id"];
  projectKey: Project["key"];
  onAddOrUpdateComplete: (rowDatum: RowDatum) => void;
}

/**
 * WARNING: ONLY MEANT TO BE IMPORTED BY TOOL PARAMS PROVIDER *
 */

export const useTablePersister = ({
  tableId,
  groupId,
  projectId,
  projectKey,
  onAddOrUpdateComplete,
}: TablePersisterHookProps) => {
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const cancelFileUploads = useFileUploadContext((s) => s.cancelFileUploads);
  const { isOnline } = useOnlineStatus();
  const [unsavedRows, setUnsavedRows] = useState<TUnsavedRow[]>([]);
  const [isSavingData, setIsSavingData] = useState(false);
  // Number of times we've failed to save some rows
  const numRetriesRef = useRef(0);

  const { updateCache: updatePageProjectCache } =
    useUpdatePageProjectCache(projectKey);

  const [getAnalysisTableGroupUsedCredits] =
    useGetAnalysisTableGroupUsedCreditsLazyQuery({
      fetchPolicy: "network-only",
    });

  /**
   * Adds a new row datum to the unsaved row data or updates an existing unsaved row if it exists
   * @param rowDatum the row datum to add or update
   * @param isNewTableRow whether the row is new to the table (user clicked add row)
   */
  const addOrUpdateUnsavedRow = useCallback(
    (
      rowDatum: RowDatum,
      options?: { isNewTableRow?: boolean; markForDeletion?: boolean },
    ) => {
      const isNewTableRow = options?.isNewTableRow;
      const markForDeletion = options?.markForDeletion;

      setUnsavedRows((prevUnsavedRowData) => {
        const existingRowIdx = prevUnsavedRowData.findIndex(
          (prevRow) => prevRow.row.id === rowDatum.id,
        );
        // row already in unsaved queue
        if (existingRowIdx > -1) {
          const newUnsavedRowData = [...prevUnsavedRowData];
          newUnsavedRowData[existingRowIdx] = {
            ...newUnsavedRowData[existingRowIdx],
            row: rowDatum,
            delete: markForDeletion,
          };
          return newUnsavedRowData;
        }
        // row not in unsaved queue
        const newUnsavedRowData: TUnsavedRow[] = [
          ...prevUnsavedRowData,
          {
            new: isNewTableRow ?? false,
            row: rowDatum,
            saveError: false,
            delete: markForDeletion,
          },
        ];
        return newUnsavedRowData;
      });
    },
    [],
  );

  /**
   * Sends mutation to create a new row
   * @param row Row datum
   * @returns created row if successful or undefined
   */

  const saveNewRow = useCallback(
    async (row: RowDatum) => {
      try {
        const newRowData = {
          id: row.id,
          table: tableId,
          data: row.params,
          tenant: currentTenant.id,
          selections: row.selections,
          attachments: {
            // TODO ID-2566 remove uniq when root cause of duplicates is found
            datasets: uniq(row.datasets),
            recordings: uniq(row.recordings),
          },
          project: projectId,
          metadatum_references: row.metadatumReferences,
          tool_version: row.toolVersion.id,
          resource_tier: row.resource_tier,
        };
        const res = await libraryAnalysisTableRowDjango.post(newRowData);

        updatePageProjectCache((data) => {
          const newData = cloneDeep(data);
          newData.project.analysisTableGroups.nodes.forEach((group) => {
            group.analysisTables.nodes = group.analysisTables.nodes.map(
              (table) => {
                if (table.id === tableId) {
                  table.rows.nodes.push({
                    ...res.data,
                    activeAttachResults: true,
                    task: null,
                  });
                }
                return table;
              },
            );
          });
          return newData;
        });

        updateCacheFragment({
          __typename: "AnalysisTable",
          id: tableId,
          update: (data) => {
            const newData = cloneDeep(data);

            if (newData.analysisTableRows === undefined) {
              newData.analysisTableRows = {
                __typename: "AnalysisTableRowsConnection",
                nodes: [],
              };
            }

            newData.analysisTableRows.nodes.push({
              __typename: "AnalysisTableRow",
              id: res.data.id,
            });

            return newData;
          },
        });

        return true;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [currentTenant.id, projectId, tableId, updatePageProjectCache],
  );

  /**
   * Sends mutation to update an existing row
   * @param row Row datum
   * @returns updated row if successful or undefined
   */

  const saveExistingRow = useCallback(async (row: RowDatum) => {
    try {
      await libraryAnalysisTableRowDjango.patch(row.id, {
        data: row.params,
        task: row.task_id,
        selections: row.selections,
        attachments: {
          // TODO ID-2566 remove uniq when root cause of duplicates is found
          datasets: uniq(row.datasets),
          recordings: uniq(row.recordings),
        },
        metadatum_references: row.metadatumReferences,
        tool_version: row.toolVersion.id,
        resource_tier: row.resource_tier,
      });
      return true;
    } catch (err) {
      captureException(err);
      return false;
    }
  }, []);

  /**
   * Sends mutation to delete an existing row
   * @param row Row datum
   * @returns updated row if successful or undefined
   */

  const saveDeleteRow = useCallback(
    async (row: RowDatum) => {
      try {
        const response = await libraryAnalysisTableRowDjango.soft_delete(
          row.id,
        );
        if (response?.status === 204) {
          // Update the cache
          updatePageProjectCache((data) => {
            const newData = cloneDeep(data);
            newData.project.analysisTableGroups.nodes.forEach((group) => {
              group.analysisTables.nodes = group.analysisTables.nodes.map(
                (table) => {
                  if (table.id === tableId) {
                    table.rows.nodes = table.rows.nodes.filter(
                      (analysisTableRow) => analysisTableRow.id !== row.id,
                    );
                  }
                  row.output_group_files?.forEach((file) => {
                    cancelFileUploads([file.id]);
                    evictCacheFragment({ __typename: "File", id: file.id });
                  });
                  return table;
                },
              );
            });
            return newData;
          });

          getAnalysisTableGroupUsedCredits({
            variables: {
              id: groupId,
            },
          }).catch((err) => captureException(err));
          return true;
        }
        return false;
      } catch (err) {
        captureException(err);
        return false;
      }
    },
    [
      cancelFileUploads,
      getAnalysisTableGroupUsedCredits,
      groupId,
      tableId,
      updatePageProjectCache,
    ],
  );

  const saveRows = useCallback(
    async (rows: TUnsavedRow[]) => {
      const saveRow = async (rowToSave: TUnsavedRow) => {
        const handleSaveResult = (rowSaved: boolean) => {
          // add failed rows back to queue
          if (!rowSaved) {
            setUnsavedRows((prevRowData) => [
              ...prevRowData,
              { ...rowToSave, saveError: true },
            ]);
          }
        };

        // user created a row and deleted it in the same debounce interval so do nothing
        if (rowToSave.delete === true && rowToSave.new) {
          return true;
        }

        if (rowToSave.delete) {
          const rowSaved = await saveDeleteRow(rowToSave.row);
          handleSaveResult(rowSaved);
          return rowSaved;
        }

        if (rowToSave.new) {
          const rowSaved = await saveNewRow(rowToSave.row);
          // Synchronize metadatum references after saving
          if (rowSaved) {
            const newRowData = await syncMetadatumReferences([rowToSave.row]);
            onAddOrUpdateComplete(newRowData[0]);
          }
          handleSaveResult(rowSaved);
          return rowSaved;
        } else {
          const rowSaved = await saveExistingRow(rowToSave.row);
          // Synchronize metadatum references after saving
          if (rowSaved) {
            const newRowData = await syncMetadatumReferences([rowToSave.row]);
            onAddOrUpdateComplete(newRowData[0]);
          }
          handleSaveResult(rowSaved);
          return rowSaved;
        }
      };

      // Reset state
      setIsSavingData(true);
      setUnsavedRows([]);

      // Whether we failed to save at least one row
      let didFailToSave = false;

      // Save each row
      for (const row of rows) {
        const wasRowSaved = await saveRow(row);
        if (!wasRowSaved) {
          didFailToSave = true;
        }
      }

      // Increment or reset retry count
      if (didFailToSave) {
        numRetriesRef.current++;
      } else {
        numRetriesRef.current = 0;
      }

      setIsSavingData(false);
    },
    [onAddOrUpdateComplete, saveDeleteRow, saveExistingRow, saveNewRow],
  );

  const saveRowsDebounced = useMemo(() => debounce(saveRows, 500), [saveRows]);

  // Reset retry count if network connection is lost
  useEffect(() => {
    if (!isOnline) {
      numRetriesRef.current = 0;
    }
  }, [isOnline]);

  // Save any unsaved rows as long as the retry limit has not been reached
  useEffect(() => {
    if (
      unsavedRows.length > 0 &&
      !isSavingData &&
      isOnline &&
      numRetriesRef.current <= 3
    ) {
      void saveRowsDebounced([...unsavedRows]);
    }
  }, [unsavedRows, isSavingData, saveRowsDebounced, isOnline]);

  const saveStatus = useMemo<SaveStatus>(() => {
    const groupedBySaveError = groupBy(unsavedRows, (row) => row.saveError);
    const errors = groupedBySaveError.true?.length ?? 0;

    if (isSavingData) {
      return "saving";
    }
    if (errors > 0) {
      return "error";
    }
    return unsavedRows.length > 0 ? "unsaved" : "saved";
  }, [unsavedRows, isSavingData]);

  /**
   * Allows consumer to trigger immediate save of all unsaved rows
   */
  const saveAllRows = useCallback(() => {
    saveRowsDebounced.cancel();
    void saveRows([...unsavedRows]);
  }, [saveRows, saveRowsDebounced, unsavedRows]);

  return {
    addOrUpdateUnsavedRow,
    saveStatus,
    saveAllRows,
  };
};
