import {
  ApplicationUser,
  Dataset,
  File as DrsFile,
  Project,
  FileRecordingGroup as RecordingGroup,
  useProjectFilesManagerQuery,
  DatasetRecordingsTableColumn,
  Task,
  FileOutputGroup,
  FileSource,
  Metadatum,
  MetadataValue,
} from "graphql/_Types";
import { ReactNode, memo, useCallback, useEffect } from "react";
import { create, StoreApi, UseBoundStore } from "zustand";
import { formatData } from "./ProjectFilesManager.helpers";
import { RecordingsGridColDef } from "components/RecordingsGrid/RecordingsGrid.types";
import AppLoading from "components/AppLoading/AppLoading";
import { CallOutError } from "components/CallOutError/CallOutError";
import { FileUploadStatus } from "types/constants";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { isNullish } from "utils/isNullish";
import { captureException } from "@sentry/react";
import { metadataMap } from "types/MetadataMap";
import { chain } from "lodash";
import { isDefined } from "utils/isDefined";

// The keys of all metadata we show within the dataset page
const viewableMetadataKeys = chain(metadataMap)
  .values()
  .filter(isDefined)
  .flatMap(Object.keys)
  .uniq()
  .value();

export type ProjectFileColumn = Pick<DatasetRecordingsTableColumn, "id"> & {
  colDef: RecordingsGridColDef;
};

type TFile = Pick<
  DrsFile,
  | "id"
  | "key"
  | "datasetId"
  | "dateCreated"
  | "name"
  | "fileType"
  | "fileFormat"
  | "fileStructure"
  | "status"
  | "uploadStatus"
  | "projectId"
  | "seriesParentId"
  | "processingStatus"
  | "originalName"
  | "seriesParentId"
> & {
  size: string;
  metadata: {
    id: Metadatum["id"];
    key: Metadatum["key"];
    value: MetadataValue["value"];
  }[];
};

export type ProjectFile = TFile & {
  source: FileSource.Uploaded | FileSource.AnalysisResult;

  user: Pick<ApplicationUser, "id" | "firstName" | "lastName">;
  recordings: (Pick<RecordingGroup, "id"> & {
    number: string;
    dataset: Pick<Dataset, "id" | "prefix">;
  })[];
  activeAssignment?: Pick<NonNullable<DrsFile["activeAssignment"]>, "id">;
  datasets: Pick<Dataset, "id" | "name">[];
  columns: ProjectFileColumn[];
  outputGroup?: Pick<FileOutputGroup, "id"> & {
    task: Pick<Task, "id">;
  };
} & (
    | { isSeries: false; seriesFiles: null }
    | {
        isSeries: true;
        seriesFiles: TFile[];
      }
  );

type ProjectFilesPrivateStoreState = {
  files: ProjectFile[];
  setFiles: (files: ProjectFile[]) => void;
};

type ProjectFilesPublicStoreState = Pick<
  ProjectFilesPrivateStoreState,
  "files"
>;

const _useProjectFilesStore = create<ProjectFilesPrivateStoreState>((set) => ({
  files: [],
  setFiles: (files) => set((s) => ({ ...s, files })),
}));

export const useProjectFilesStore: UseBoundStore<
  StoreApi<ProjectFilesPublicStoreState>
> = _useProjectFilesStore;

interface ProjectFilesManagerProps {
  children: ReactNode;
  projectId: Project["id"];
  cutoffTime?: string;
}

export const ProjectFilesManager = memo(function ProjectFilesManager({
  children,
  projectId,
  cutoffTime,
}: ProjectFilesManagerProps) {
  const fileUploadStoreInitTime = useFileUploadContext((s) => s.initTime);

  /**
   * Determines whether a file represents a failed upload from another
   * session. Files like these are stuck with a "created" status and
   * were created before the File Upload Store was created.
   * @param drsFile
   * @returns A `boolean` representing whether the file failed to upload
   */
  const isFailedUploadFromOtherSession = useCallback(
    (
      drsFile: Pick<
        DrsFile,
        "status" | "dateCreated" | "uploadStatus" | "source"
      >,
    ) => {
      return (
        drsFile.source === FileSource.Uploaded &&
        drsFile.uploadStatus !== FileUploadStatus["COMPLETE"] &&
        drsFile.dateCreated < fileUploadStoreInitTime
      );
    },
    [fileUploadStoreInitTime],
  );

  const setFiles = _useProjectFilesStore((s) => s.setFiles);

  // Reset store on umount
  useEffect(() => {
    return () => setFiles([]);
  }, [setFiles]);

  const { data, error } = useProjectFilesManagerQuery({
    variables: {
      projectId,
      cutoffTime: cutoffTime ?? null,
      metadataKeys: viewableMetadataKeys,
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      const { project } = data;
      if (isNullish(project)) {
        throw new Error("Missing data in project files manager");
      }

      const files = formatData(project)
        // Filter out files that failed to upload
        .filter((file) => !isFailedUploadFromOtherSession(file));
      setFiles(files);
    }
  }, [data, isFailedUploadFromOtherSession, setFiles]);

  if (error !== undefined) {
    captureException(error);
    return <CallOutError />;
  }

  if (data === undefined) {
    return <AppLoading />;
  }

  return <>{children}</>;
});
