import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import assert from "assert";
import axios, {
  AxiosError,
  AxiosProgressEvent,
  CancelTokenSource,
} from "axios";
import { isDefined } from "../../utils/isDefined";
import { getEnvVar } from "../../ideas.env";
import { getRequestHeaders } from "utils/getRequestHeaders";
import { Tenant } from "graphql/_Types";
import { useUploadUrlCache } from "./useUploadUrlCache";
import { useOnlineStatus } from "hooks/useOnlineStatus";
import { captureException } from "@sentry/react";
import { sleep } from "utils/sleep";
import { get } from "lodash";

type Options = {
  file: {
    id: string;
    blob: Blob;
    partSize: number;
    tenantId: Tenant["id"];
  };
  onUploadProgress?: (uploadProgress: number) => void;
};

type FilePart = {
  blob: Blob;
  bytesUploaded: number;
  status: "queued" | "uploading" | "uploaded" | "failed";
  // The number of times the file part has attempted uploading
  retryCount: number;
};

/* The maximum number of times we will attempt to upload a file part before
   reaching a terminal error state */
const MAX_RETRIES = 5;

/**
 * Extracts the raw data for a file part
 * @param partNumber The file part number (1-indexed)
 * @returns The raw file part data
 */
const getFilePartBlob = (file: Options["file"], partNumber: number) => {
  const start = (partNumber - 1) * file.partSize;
  const end = start + file.partSize;
  return file.blob.slice(start, end);
};

/**
 * Checks whether an axios error is a Django error with a specified code.
 * @param error
 * @param code
 * @returns `true` if the axios error matches the code, `false` otherwise.
 */
const hasErrorCode = (error: AxiosError, code: string) => {
  const errors = get(error, "response.data.errors") as
    | { code: string; detail: string }[]
    | undefined;
  return isDefined(errors) && errors.some((error) => error.code === code);
};

/** Represents the error thrown when an uploading file is cancelled */
export class UploadCancelledError extends Error {
  constructor() {
    super("Upload cancelled");
  }
}

/** Represents a reason for canceling an in-flight network request */
enum RequestCanceledReason {
  UPLOAD_CANCELED = "Upload canceled",
  UPLOAD_PAUSED = "Upload paused",
}

/**
 * A hook for uploading a file by segmenting it into multiple parts
 */
export const useUploadMultiPartFile = () => {
  const maxConnections = 6; // max connections allowable by HTTP/1.1
  const fileRef = useRef<Options["file"]>();
  const [fileParts, setFileParts] = useState<FilePart[]>();
  const onUploadProgressRef = useRef<(uploadProgress: number) => void>();
  const onCompleteRef = useRef<() => void>();
  const onErrorRef = useRef<(error: Error) => void>();
  const { getFilePartUploadUrls, invalidateUploadUrlCache } =
    useUploadUrlCache();
  const { isOnline } = useOnlineStatus();
  const [isPaused, setIsPaused] = useState(false);
  const cancelTokensRef = useRef<CancelTokenSource[]>([]);

  /* Pause uploads when offline. Resume uploads when online. */

  useEffect(() => {
    if (fileRef.current !== undefined) {
      setIsPaused(!isOnline);
    }
  }, [isOnline]);

  /* Abort all active network requests when uploading is paused. If we don't do
     this, the requests will hang indefinitely. Depending on the duration of 
     the network outage, requests could also be using upload URLs that have
     become stale. */

  useEffect(() => {
    if (isPaused) {
      cancelTokensRef.current.forEach((token) =>
        token.cancel(RequestCanceledReason.UPLOAD_PAUSED),
      );
    }
  }, [isPaused]);

  /**
   * Resets the hook's state
   */
  const resetState = useCallback(() => {
    onUploadProgressRef.current = undefined;
    setFileParts(undefined);
    cancelTokensRef.current = [];
    onUploadProgressRef.current = undefined;
    onCompleteRef.current = undefined;
    onErrorRef.current = undefined;
  }, []);

  /**
   * Updates a specific file part in the hook state
   * @param partNumber
   * @param attrs The updated file part attributes
   */
  const updateFilePart = useCallback(
    (partNumber: number, attrs: Partial<FilePart>) => {
      setFileParts((prevFileParts) => {
        assert(
          isDefined(prevFileParts),
          "Expected previous file parts to be defined",
        );
        const newFileParts = [...prevFileParts];
        const filePart = newFileParts[partNumber - 1];
        Object.assign(filePart, attrs);
        return newFileParts;
      });
    },
    [],
  );

  /**
   * Uploads an individual file part
   * @param partNumber
   */
  const uploadFilePart = useCallback(
    async (partNumber: number) => {
      try {
        const file = fileRef.current;
        assert(isDefined(file), "Expected file to be defined");
        assert(isDefined(fileParts), "Expected fileParts to be defined");

        updateFilePart(partNumber, { status: "uploading" });
        const allUploadUrls = await getFilePartUploadUrls(
          file.id,
          file.tenantId,
        );
        const uploadUrl = allUploadUrls[partNumber - 1];
        const filePart = fileParts[partNumber - 1];
        const { blob } = filePart;

        const cancelToken = axios.CancelToken.source();
        cancelTokensRef.current.push(cancelToken);

        try {
          await axios.put(uploadUrl, blob, {
            headers: { "Content-Type": "" },
            onUploadProgress: (event: AxiosProgressEvent) => {
              const { loaded: bytesUploaded } = event;
              updateFilePart(partNumber, { bytesUploaded });
            },
            cancelToken: cancelToken.token,
          });

          updateFilePart(partNumber, {
            status: "uploaded",
          });
        } catch (error) {
          // Requeue file part if request was cancelled during a network outage
          if (axios.isCancel(error)) {
            if (error.message === RequestCanceledReason.UPLOAD_PAUSED) {
              updateFilePart(partNumber, {
                bytesUploaded: 0,
                status: "queued",
                retryCount: filePart.retryCount + 1,
              });
            }
          }
          // Requeue file part after exponential timeout if within retry limit
          else if (filePart.retryCount < MAX_RETRIES) {
            // Mark all cached URLs as stale
            invalidateUploadUrlCache(file.id);

            // Calculate an exponential timeout from the retry count
            const timeout = Math.pow(2, filePart.retryCount) * 1000;
            await sleep(timeout);

            // Requeue the file part for upload
            updateFilePart(partNumber, {
              bytesUploaded: 0,
              status: "queued",
              retryCount: filePart.retryCount + 1,
            });
          }
          // Mark file part as failed if retry limit exceeded
          else {
            updateFilePart(partNumber, { status: "failed" });
          }
        }
      } catch (error) {
        updateFilePart(partNumber, { status: "failed" });
      }
    },
    [
      fileParts,
      getFilePartUploadUrls,
      invalidateUploadUrlCache,
      updateFilePart,
    ],
  );

  /**
   * Marks the file upload as failed
   */
  const failFileUpload = useCallback(() => {
    const error = new Error("Failed to upload file");
    captureException(error);
    const onError = onErrorRef.current;
    assert(isDefined(onError), "Expected onErrorRef to be defined");
    onError(error);
  }, []);

  /**
   * Aborts all active network requests when the current uploading file is
   * deleted. We also invalidate the presigned URL cache to avoid retrying the
   * file part uploads after the requests are aborted.
   * @param fileId
   */
  const cancelFileUpload = useCallback(
    (fileId: string) => {
      if (fileId === fileRef.current?.id) {
        invalidateUploadUrlCache(fileId);
        cancelTokensRef.current.forEach((token) =>
          token.cancel(RequestCanceledReason.UPLOAD_CANCELED),
        );
        if (isDefined(onErrorRef.current)) {
          onErrorRef.current(new UploadCancelledError());
        }
      }
    },
    [invalidateUploadUrlCache],
  );

  /**
   * Marks the file upload as completed
   */
  const completeFileUpload = useCallback(async () => {
    try {
      const file = fileRef.current;
      assert(isDefined(file), "Expected fileId to be defined");
      const baseUrl = getEnvVar("URL_DRS_FILE_COMPLETE_UPLOAD");
      const url = `${baseUrl}${file.id}/`;
      const headers = await getRequestHeaders({ tenantId: file.tenantId });
      await axios.post(url, {}, { headers });
      const onComplete = onCompleteRef.current;
      assert(isDefined(onComplete), "Expected onCompleteRef to be defined");
      onComplete();
    } catch (err) {
      failFileUpload();
    }
  }, [failFileUpload]);

  /**
   * Uploads a file to a dataset by segmenting it into multiple parts
   * @param file
   * @param options
   */
  const uploadMultiPartFile = useCallback(
    async ({ file, onUploadProgress }: Options) => {
      resetState();
      fileRef.current = file;
      onUploadProgressRef.current = onUploadProgress;

      const numParts = Math.ceil(file.blob.size / file.partSize);
      const fileParts = Array.from({ length: numParts }, (_, idx) => {
        const partNumber = idx + 1;
        const blob = getFilePartBlob(file, partNumber);
        return {
          blob,
          bytesUploaded: 0,
          status: "queued" as const,
          retryCount: 0,
        };
      });

      // Get initial upload URLs and check whether upload was cancelled
      try {
        await getFilePartUploadUrls(file.id, file.tenantId);
        setFileParts(fileParts);
      } catch (error) {
        if (
          error instanceof AxiosError &&
          hasErrorCode(error, "file_wrong_state_for_upload")
        ) {
          throw new UploadCancelledError();
        } else {
          throw error;
        }
      }

      return new Promise<void>((resolve, reject) => {
        onCompleteRef.current = resolve;
        onErrorRef.current = reject;
      });
    },
    [getFilePartUploadUrls, resetState],
  );

  /**
   * Monitors and queues file parts for upload
   */
  useEffect(() => {
    // Do nothing until the file parts are initialized
    if (fileParts === undefined) {
      return;
    }

    const numPartsQueued = fileParts.reduce((sum, filePart) => {
      return filePart.status === "queued" ? sum + 1 : sum;
    }, 0);

    const numPartsUploading = fileParts.reduce((sum, filePart) => {
      return filePart.status === "uploading" ? sum + 1 : sum;
    }, 0);

    const numPartsUploaded = fileParts.reduce((sum, filePart) => {
      return filePart.status === "uploaded" ? sum + 1 : sum;
    }, 0);

    const numPartsFailed = fileParts.reduce((sum, filePart) => {
      return filePart.status === "failed" ? sum + 1 : sum;
    }, 0);

    // Upload file parts that have yet to be uploaded
    if (
      numPartsUploading < maxConnections &&
      numPartsQueued > 0 &&
      numPartsFailed === 0 &&
      !isPaused
    ) {
      const partNumber =
        // Need to add 1 because AWS file part numbers are 1-indexed
        fileParts.findIndex((filePart) => filePart.status === "queued") + 1;
      void uploadFilePart(partNumber);
    }

    // Complete the upload when all parts have finished uploading
    if (numPartsUploaded === fileParts.length && !isPaused) {
      void completeFileUpload();
    }

    // Fail the upload when a part fails to upload and there are no more parts in flight
    if (numPartsFailed > 0 && numPartsUploading === 0) {
      void failFileUpload();
    }
  }, [completeFileUpload, failFileUpload, fileParts, isPaused, uploadFilePart]);

  /**
   * The percentage (from 0 to 100) of the file's bytes that have uploaded
   */
  const progress = useMemo(() => {
    if (fileParts === undefined) {
      return;
    }

    const bytesUploaded = fileParts.reduce((sum, filePart) => {
      return sum + filePart.bytesUploaded;
    }, 0);

    const totalBytes = fileParts.reduce((sum, filePart) => {
      return sum + filePart.blob.size;
    }, 0);

    return Math.round((bytesUploaded * 100) / totalBytes);
  }, [fileParts]);

  useEffect(() => {
    const onUploadProgress = onUploadProgressRef.current;
    if (onUploadProgress !== undefined && progress !== undefined) {
      onUploadProgress(progress);
    }
  }, [progress]);

  return { uploadMultiPartFile, cancelFileUpload };
};
