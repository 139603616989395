/** @jsxImportSource @emotion/react */
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { EuiButtonEmpty, EuiIcon, EuiPopover } from "@inscopix/ideas-eui";
import { IHeaderParams } from "ag-grid-community";
import { css } from "@emotion/react";
import { isNonNull } from "utils/isNonNull";
import assert from "assert";
import { useDatasetAction } from "hooks/useDatasetAction/useDatasetAction";
import { useDatasetLayoutContext } from "pages/project/dataset/DatasetLayoutProvider";
import {
  AnalysisResultColumn,
  DrsFileColumn,
  isAnalysisResultColumn,
  isDrsFileColumn,
  isLinkedMetadataColumn,
  isMetadataColumn,
  LinkedMetadataColumn,
  MetadataColumn,
} from "../RecordingsGrid.helpers";
import { ButtonEmptyPermissioned } from "components/ButtonEmptyPermissioned/ButtonEmptyPermissioned";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  name: css`
    background: none;
    border: none;
    cursor: default;
    flex: 1;
    margin: 0px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `,
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
};

interface RecordingsGridColumnHeaderProps
  extends Omit<IHeaderParams, "column"> {
  column:
    | AnalysisResultColumn<{ pinned: boolean }>
    | LinkedMetadataColumn<{ pinned: boolean }>
    | MetadataColumn<{ pinned: boolean }>
    | DrsFileColumn<{ pinned: boolean }>;
  icon?: ReactNode;
}

export const RecordingsGridColumnHeader = ({
  column,
  api,
  displayName,
  icon,
}: RecordingsGridColumnHeaderProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { openModal } = useDatasetLayoutContext();
  const renameColumnAction = useDatasetAction("renameColumn");
  const deleteColumnAction = useDatasetAction("deleteColumn");
  const pinColumnAction = useDatasetAction("pinColumn");
  const unpinColumnAction = useDatasetAction("unpinColumn");

  /* Update the header name on prop changes */

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.value = displayName;
    }
  }, [displayName]);

  const handleBlur = () => {
    assert(isNonNull(inputRef.current), "Expected ref to be non null");
    const newName = inputRef.current.value;

    if (newName !== displayName) {
      const columnId = column.id;
      void renameColumnAction.enqueue({ columnId, newName });
    }

    // Ensure the leftmost characters are visible
    inputRef.current.scroll({ left: 0 });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    assert(isNonNull(inputRef.current), "Expected ref to be non null");
    inputRef.current.value = e.target.value;
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    // TODO: Find a more extensible way to detect event triggers
    const isTriggeredByHeaderMenu =
      e.relatedTarget?.classList.contains("euiButtonEmpty");

    if (isTriggeredByHeaderMenu) {
      inputRef.current?.select();
    } else {
      inputRef.current?.blur();
    }
  };

  const handleKeyDownCapture = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    assert(isNonNull(inputRef.current), "Expected ref to be non null");

    if (e.key === "Enter") {
      inputRef.current.blur();
    }

    if (e.key === "Escape") {
      inputRef.current.value = displayName; // Revert changes
      inputRef.current.blur();
    }
  };

  return (
    <div css={styles.root}>
      {icon}
      <input
        css={styles.name}
        defaultValue={displayName}
        onBlur={handleBlur}
        onChange={handleChange}
        onDragStart={(e) => e.preventDefault()}
        onFocus={handleFocus}
        onKeyDownCapture={handleKeyDownCapture}
        ref={inputRef}
        type="text"
      />
      {column.pinned && (
        <EuiIcon
          aria-label="Pinned"
          color="subdued"
          size="s"
          type="pinFilled"
        />
      )}
      {(isDrsFileColumn(column) ||
        isMetadataColumn(column) ||
        isLinkedMetadataColumn(column) ||
        isAnalysisResultColumn(column)) && (
        <EuiPopover
          button={
            <span
              className="ag-header-cell-menu-button"
              aria-hidden="true"
              data-test-subj="headerCellMenuButton"
              onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
            >
              <span className="ag-icon ag-icon-menu" unselectable="on" />
            </span>
          }
          isOpen={isPopoverOpen}
          closePopover={() => setIsPopoverOpen(false)}
          anchorPosition="downLeft"
          panelPaddingSize="none"
          ownFocus={false}
        >
          <div css={styles.contextMenu}>
            {!isAnalysisResultColumn(column) && (
              <>
                <EuiButtonEmpty
                  aria-label="View Details"
                  data-test-subj="viewColumnDetails"
                  onClick={() => {
                    if (isLinkedMetadataColumn(column)) {
                      openModal({
                        type: "viewLinkedMetadataColumn",
                        props: { column },
                      });
                    }
                    if (isMetadataColumn(column)) {
                      openModal({
                        type: "viewMetadataColumn",
                        props: { column },
                      });
                    }
                    if (isDrsFileColumn(column)) {
                      openModal({
                        type: "viewDrsFileColumn",
                        props: { column },
                      });
                    }
                    setIsPopoverOpen(false);
                  }}
                  iconType="iInCircle"
                  color="text"
                  size="s"
                >
                  Details
                </EuiButtonEmpty>
                <ButtonEmptyPermissioned
                  onClick={() => {
                    inputRef.current?.select();
                    setIsPopoverOpen(false);
                  }}
                  iconType="pencil"
                  color="text"
                  size="s"
                  disabled={renameColumnAction.isDisabled}
                  requiredPermission="edit"
                >
                  Rename column
                </ButtonEmptyPermissioned>
              </>
            )}

            {column.pinned ? (
              <ButtonEmptyPermissioned
                aria-label="Unpin column"
                onClick={() => {
                  void unpinColumnAction.enqueue({ columnId: column.id });
                  setIsPopoverOpen(false);
                }}
                iconType="pin"
                color="text"
                size="s"
                disabled={unpinColumnAction.isDisabled}
                requiredPermission="edit"
              >
                Unpin column
              </ButtonEmptyPermissioned>
            ) : (
              <ButtonEmptyPermissioned
                aria-label="Pin column"
                onClick={() => {
                  void pinColumnAction.enqueue({ columnId: column.id });
                  setIsPopoverOpen(false);
                }}
                iconType="pin"
                color="text"
                size="s"
                disabled={pinColumnAction.isDisabled}
                requiredPermission="edit"
              >
                Pin column
              </ButtonEmptyPermissioned>
            )}
            <ButtonEmptyPermissioned
              aria-label="Remove column"
              onClick={() => {
                const nativeAgColumn = api.getColumn(column.id);
                if (nativeAgColumn !== null) {
                  openModal({
                    type: "removeColumns",
                    props: {
                      targetColumn: nativeAgColumn,
                    },
                  });
                }
                setIsPopoverOpen(false);
              }}
              iconType="trash"
              color="text"
              size="s"
              disabled={deleteColumnAction.isDisabled}
              requiredPermission="edit"
            >
              {isDrsFileColumn(column) || isMetadataColumn(column)
                ? "Delete column"
                : "Remove column"}
            </ButtonEmptyPermissioned>
          </div>
        </EuiPopover>
      )}
    </div>
  );
};
