import { useProjectFilesStore } from "stores/project-files/ProjectFilesManager";
import { RecordingIdentifierBadge } from "components/RecordingIdentifierBadge/RecordingIdentifierBadge";
import {
  FlyoutDrsFileInfoBase,
  FlyoutDrsFileInfoBaseProps,
} from "./FlyoutDrsFileInfoBase";
import { EuiBadgeGroup } from "@inscopix/ideas-eui";
import { memo, ReactNode, useCallback, useState } from "react";
import { ModalDeleteDrsFile } from "components/ModalDeleteDrsFile/ModalDeleteDrsFile";
import { ButtonDeleteDrsFileProps } from "components/ButtonDeleteDrsFile/ButtonDeleteDrsFile";

interface FlyoutDrsFileInfoAssignmentsProps {
  fileId: string;
}

const FlyoutDrsFileInfoAssignments = ({
  fileId,
}: FlyoutDrsFileInfoAssignmentsProps) => {
  const recordings =
    useProjectFilesStore(
      (s) => s.files.find((file) => file.id === fileId)?.recordings,
    ) ?? [];

  if (recordings.length === 0) {
    return <span>Unassigned</span>;
  }

  return (
    <EuiBadgeGroup>
      {recordings.map(({ id }) => (
        <RecordingIdentifierBadge key={id} showShortIdOnly recordingId={id} />
      ))}
    </EuiBadgeGroup>
  );
};

export type FlyoutDrsFileInfoProps = Omit<
  FlyoutDrsFileInfoBaseProps,
  "assignments" | "onDelete"
>;

export const FlyoutDrsFileInfo = memo(function FlyoutDrsFileInfo(
  props: FlyoutDrsFileInfoProps,
) {
  const [modal, setModal] = useState<ReactNode>(null);

  const handleDelete = useCallback(
    (file: ButtonDeleteDrsFileProps["drsFile"]) => {
      setModal(
        <ModalDeleteDrsFile drsFiles={[file]} onClose={() => setModal(null)} />,
      );
    },
    [],
  );

  return (
    <>
      {modal}
      <FlyoutDrsFileInfoBase
        {...props}
        assignments={FlyoutDrsFileInfoAssignments}
        onDelete={handleDelete}
      />
    </>
  );
});
