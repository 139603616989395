import { FileBadge } from "components/FileBadge/FileBadge";
import { useCellValueRendererFileQuery } from "graphql/_Types";
import {
  CellError,
  DetailedCellError,
  ErrorType,
  IdeasFile,
} from "@inscopix/ideas-hyperformula";
import { useDataTableContext } from "../store/DataTableProvider";
import { gql } from "@apollo/client";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import { CellValueRendererLoading } from "./CellValueRendererLoading";
import { CellValueRendererError } from "./CellValueRendererError";

gql`
  query CellValueRendererFile($fileId: UUID!) {
    file: fileById(id: $fileId) {
      id
      name
      status
      fileType
      isSeries
      source
      processingStatus
      seriesParentId
    }
  }
`;

interface CellValueRendererFileProps {
  file: IdeasFile;
}

/** Component that renders an `IdeasFile` cell value` */
export const CellValueRendererFile = (props: CellValueRendererFileProps) => {
  const fileId = props.file.attrs.id;
  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const isSelected = useDataTableContext((s) => s.selectedFileId === fileId);
  const { data, loading } = useCellValueRendererFileQuery({
    variables: { fileId },
  });

  if (loading) {
    return <CellValueRendererLoading />;
  }

  const file = data?.file;
  if (isNullish(file)) {
    const msg = "File not found";
    const error = new DetailedCellError(
      new CellError(ErrorType.ERROR, msg),
      msg,
    );
    return <CellValueRendererError error={error} />;
  }

  return (
    <FileBadge
      drsFile={{
        id: file.id,
        name: file.name,
        status: file.status,
        fileType: file.fileType,
        isSeries: file.isSeries,
        source: file.source,
        processingStatus: file.processingStatus,
        seriesParentId: file.seriesParentId,
      }}
      isSelected={isSelected}
      onClick={() => {
        if (isSelected) {
          setSelectedFileId(undefined);
        } else {
          setSelectedFileId(file.id);
        }
      }}
    />
  );
};
