import { Fragment, useEffect, useRef, useState } from "react";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldColumn } from "../../fields/FieldColumn";
import { FieldFileMetadatum } from "../../fields/FieldFileMetadatum";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { PanelProps } from "../ModalCreateColumn";
import { getColumnAddress } from "pages/gdt/column-headers/ColumnHeaderBase";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";

export const PanelCreateColumnFileMetadatum = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const metadatumFieldRef = useRef<HTMLInputElement | null>(null);
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const [fileColumn, setFileColumn] = useState<{
    id: string;
    name: string;
    order: number;
    kind: "data_table_column" | "gdt_analysis_table_column";
    definition: DataTableColumnDefinition;
  }>();
  const [metadatumKey, setMetadatumKey] = useState<string>();

  // Update column definition
  useEffect(() => {
    if (fileColumn !== undefined && metadatumKey !== undefined) {
      onFieldChange("definition", {
        value: {
          kind: "metadatum",
          file_column: fileColumn,
          metadatum_key: metadatumKey,
        },
        isValid: true,
      });
    } else {
      onFieldChange("definition", {
        value: undefined,
        isValid: false,
      });
    }
  }, [fileColumn, metadatumKey, onFieldChange]);

  /**
   * Shifts focus to the metadatum field.
   *
   * This is tried multiple times because EuiComboBox (used by FieldColumn)
   * refocuses itself after onChange is called. I believe this is a bug in EUI
   * but I think this is the best option in the absence of a long-term fix.
   */
  const focusMetadatumField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== metadatumFieldRef.current) {
        metadatumFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  /**
   * Shifts focus to the name field.
   *
   * This is tried multiple times because EuiSuperSelect (used by
   * FieldFileMetadatum) refocuses itself after onChange is called. I believe
   * this is a bug in EUI but I think this is the best option in the absence of
   * a long-term fix.
   */
  const focusNameField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== nameFieldRef.current) {
        nameFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  return (
    <Fragment>
      <FieldColumn
        autoFocus
        label="File column"
        helpText="Choose a file column to provide the value for this metadatum column."
        filter={{ columnKind: "file", tableId: selectedTableId }}
        value={fileColumn?.id}
        onChange={(value) => {
          // Update state
          setFileColumn(value);
          setMetadatumKey(undefined);

          // Shift focus to metadatum field
          focusMetadatumField();
        }}
      />

      <FieldFileMetadatum
        autoFocus
        inputRef={(ref) => (metadatumFieldRef.current = ref)}
        disabled={fileColumn === undefined}
        fileType={
          fileColumn?.definition.kind === "file"
            ? fileColumn.definition.file_type
            : undefined
        }
        value={metadatumKey}
        onChange={(value) => {
          // Update state
          setMetadatumKey(value?.key);

          // Update default formula
          if (value !== undefined && fileColumn !== undefined) {
            const colAddress = getColumnAddress(fileColumn.order);
            onFieldChange("default_formula", {
              value: `=METADATUM(${colAddress}:${colAddress}, "${value.key}")`,
              isValid: true,
            });
          }

          // Auto-fill name field if not previously edited
          if (value !== undefined && !fields.name.isDirty) {
            onFieldChange("name", {
              value: value.name,
              isValid: true,
              isDirty: false,
            });
          }

          // Shift focus to name field
          focusNameField();
        }}
      />

      <FieldColumnName
        ref={nameFieldRef}
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />
    </Fragment>
  );
};
