import { Fragment, useEffect, useRef, useState } from "react";
import { FileType } from "types/FileTypes";
import { FieldColumnName } from "../../fields/FieldColumnName";
import { FieldFileType } from "../../fields/FieldFileType";
import { PanelProps } from "../ModalCreateColumn";

export const PanelCreateColumnFile = ({
  fields,
  onFieldChange,
}: PanelProps) => {
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const [fileType, setFileType] = useState<FileType["id"]>();

  // Update column definition
  useEffect(() => {
    if (fileType !== undefined) {
      onFieldChange("definition", {
        value: {
          kind: "file",
          file_type: fileType,
        },
        isValid: true,
      });
    } else {
      onFieldChange("definition", {
        value: undefined,
        isValid: false,
      });
    }
  }, [fileType, onFieldChange]);

  /**
   * Shifts focus to the name field.
   *
   * This is tried multiple times because EuiSuperSelect (used by FieldFileType)
   * refocuses itself after onChange is called. I believe this is a bug in EUI
   * but I think this is the best option in the absence of a long-term fix.
   */
  const focusNameField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== nameFieldRef.current) {
        nameFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  return (
    <Fragment>
      <FieldFileType
        autoFocus
        value={fileType}
        onChange={(value) => {
          // Update state
          setFileType(value?.id);

          // Auto-fill name field if not previously edited
          if (value !== undefined && !fields.name.isDirty) {
            onFieldChange("name", {
              value: value.name,
              isValid: true,
              isDirty: false,
            });
          }

          // Shift focus to name field
          focusNameField();
        }}
      />

      <FieldColumnName
        ref={nameFieldRef}
        value={fields.name.value}
        onChange={(field) => {
          onFieldChange("name", {
            value: field.value,
            isValid: field.isValid,
            isDirty: true,
          });
        }}
      />
    </Fragment>
  );
};
