import {
  EuiBadge,
  EuiSuperSelect,
  EuiSuperSelectOption,
} from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

interface FieldDefaultFormulaIdentifierProps {
  autoFocus?: boolean;
  tableId: string | undefined;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const FieldDefaultFormulaIdentifier = ({
  autoFocus,
  tableId,
  value,
  onChange,
}: FieldDefaultFormulaIdentifierProps) => {
  const table = useDataTableContext((s) =>
    s.tables.find((table) => table.id === tableId),
  );

  const options: EuiSuperSelectOption<string>[] =
    table !== undefined
      ? table.rows.map((row) => ({
          value: `=ROW_IDENTIFIER("${table.key}", ${row.index})`,
          inputDisplay: (
            <EuiBadge
              color="#ddd"
              style={{ fontFamily: "monospace", border: "1px solid grey" }}
            >
              {table?.key}-{row.index}
            </EuiBadge>
          ),
        }))
      : [];

  const handleChange = (newValue: string) => {
    if (newValue === value) {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  return (
    <FieldDefaultFormula>
      <EuiSuperSelect
        autoFocus={autoFocus}
        options={options}
        valueOfSelected={value?.toString()}
        onChange={handleChange}
        placeholder="None"
        hasDividers
      />
    </FieldDefaultFormula>
  );
};
