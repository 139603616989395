import {
  EuiEmptyPrompt,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPopover,
  EuiText,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { PopoverFileUploadTitle } from "./PopoverFileUploadTitle";
import { PopoverFileUploadContent } from "./PopoverFileUploadContent";
import { DRS_FILE_ACTION_ICONS } from "types/Icons";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";

export const PopoverFileUpload = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);

  // Number of files uploaded in this session
  const numFiles = useFileUploadContext((s) => Object.keys(s.filesById).length);

  // Number of files currently uploading or waiting to be uploaded
  const numUploadingFiles = useFileUploadContext((s) => {
    const uploadingFiles = Object.values(s.filesById).filter(
      (file) => file?.progress !== 100 && file?.error === undefined,
    );
    return uploadingFiles.length;
  });

  return (
    <EuiPopover
      anchorPosition="downCenter"
      button={
        <EuiHeaderSectionItemButton
          aria-controls="headerFileUploadPopover"
          aria-expanded={isPopoverOpen}
          aria-haspopup="true"
          aria-label="Uploads"
          onClick={togglePopover}
          notification={numUploadingFiles}
        >
          <EuiIcon type={DRS_FILE_ACTION_ICONS["upload"]} />
        </EuiHeaderSectionItemButton>
      }
      closePopover={closePopover}
      isOpen={isPopoverOpen}
    >
      {numFiles > 0 ? (
        <>
          <PopoverFileUploadTitle />
          <div style={{ width: 500 }}>
            <PopoverFileUploadContent />
          </div>
        </>
      ) : (
        <EuiEmptyPrompt
          title={<h2>No files uploading</h2>}
          style={{ maxWidth: "100%" }}
          titleSize="xs"
          iconType={DRS_FILE_ACTION_ICONS["upload"]}
          body={
            <EuiText>
              Navigate to a project dataset to begin uploading files.
            </EuiText>
          }
        />
      )}
    </EuiPopover>
  );
};
