import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageTemplate,
} from "@inscopix/ideas-eui";
import { useParams } from "react-router-dom";
import { DataTableContent } from "./layout/DataTableContent";
import { DataTableHeader } from "./layout/DataTableHeader";
import { DataTableMenuBar } from "./layout/DataTableMenuBar";
import { DataTableTabs } from "./layout/DataTableTabs";
import { DataTableProvider } from "./store/DataTableProvider";

export const PageGenericDataTables = () => {
  const projectKey = useParams<{ projectKey: string }>().projectKey;

  return (
    <EuiPageTemplate style={{ height: "100%" }}>
      <DataTableProvider projectKey={projectKey}>
        <EuiFlexGroup
          direction="column"
          gutterSize="none"
          style={{ height: "100%" }}
        >
          <EuiFlexItem grow={false} style={{ paddingLeft: 5 }}>
            <DataTableHeader />
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ paddingLeft: 5 }}>
            <DataTableMenuBar />
          </EuiFlexItem>

          <EuiFlexItem style={{ minHeight: 0 }}>
            <DataTableContent />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <DataTableTabs />
          </EuiFlexItem>
        </EuiFlexGroup>
      </DataTableProvider>
    </EuiPageTemplate>
  );
};
