import { memo, ReactNode, useCallback, useState } from "react";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { CellValueRendererRowIdentifier } from "pages/gdt/cell-value-renderers/CellValueRendererRowIdentifier";
import {
  FlyoutDrsFileInfoBase,
  FlyoutDrsFileInfoBaseProps,
} from "./FlyoutDrsFileInfoBase";
import { EuiBadgeGroup } from "@inscopix/ideas-eui";
import { ButtonDeleteDrsFileProps } from "components/ButtonDeleteDrsFile/ButtonDeleteDrsFile";
import { ModalDeleteDrsFileGeneric } from "components/ModalDeleteDrsFile/ModalDeleteDrsFileGeneric";

interface FlyoutDrsFileInfoGenericAssignmentsProps {
  fileId: string;
}

const FlyoutDrsFileInfoGenericAssignments = ({
  fileId,
}: FlyoutDrsFileInfoGenericAssignmentsProps) => {
  const identifiers = useDataTableContext((s) => s.getFileAssignments(fileId));

  if (identifiers.length === 0) {
    return <span>Unassigned</span>;
  }

  return (
    <EuiBadgeGroup>
      {identifiers.map((identifier) => (
        <CellValueRendererRowIdentifier
          key={`${identifier.tableKey}-${identifier.rowIndex}`}
          rowIdentifier={identifier}
        />
      ))}
    </EuiBadgeGroup>
  );
};

export type FlyoutDrsFileInfoGenericProps = Omit<
  FlyoutDrsFileInfoBaseProps,
  "assignments" | "onDelete"
>;

export const FlyoutDrsFileInfoGeneric = memo(function FlyoutDrsFileInfoGeneric(
  props: FlyoutDrsFileInfoGenericProps,
) {
  const [modal, setModal] = useState<ReactNode>(null);

  const handleDelete = useCallback(
    (file: ButtonDeleteDrsFileProps["drsFile"]) => {
      setModal(
        <ModalDeleteDrsFileGeneric
          file={file}
          onClose={() => setModal(null)}
        />,
      );
    },
    [],
  );

  return (
    <>
      {modal}
      <FlyoutDrsFileInfoBase
        {...props}
        assignments={FlyoutDrsFileInfoGenericAssignments}
        onDelete={handleDelete}
      />
    </>
  );
});
