import { File as DrsFile, FileSource, Project } from "graphql/_Types";
import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { FileStatus, ProcessingStatus } from "types/constants";
import { updateCacheFragment, writeCacheFragment } from "utils/cache-fragments";
import { useCreateFileDjango } from "./useCreateFileDjango";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { identifyFile } from "utils/identifyFile";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";

type WriteCacheParams = Pick<
  DrsFile,
  "id" | "name" | "datasetId" | "dateCreated" | "tenantId"
> & {
  project: Pick<Project, "id" | "key" | "name" | "tenantId">;
};

interface EnqueueUploadParams extends WriteCacheParams {
  blob: File;
}
export const useUploadFile = () => {
  const currentUser = useUserContext((s) => s.currentUser);
  const currentTenant = useTenantContext((s) => s.currentTenant);
  const { createFile } = useCreateFileDjango();
  const enqueueFile = useFileUploadContext((s) => s.enqueueFile);

  const writeCache = useCallback(
    (params: WriteCacheParams) => {
      // Create a cache record for the file
      writeCacheFragment({
        __typename: "File",
        id: params.id,
        data: {
          __typename: "File",
          id: params.id,
          dateArchived: null,
          dateCreated: params.dateCreated,
          dateDataDeleted: null,
          dateDeleted: null,
          fileFormat: null,
          fileStructure: null,
          fileType: null,
          key: null,
          location: null,
          multipartUploadId: null,
          name: params.name,
          parentIds: null,
          partSize: null,
          preview: null,
          signal: null,
          size: null,
          status: FileStatus.CREATED,
          source: FileSource.Uploaded,
          tenantId: currentTenant.id,
          uploadStatus: null,
          userId: currentUser.id,
          projectId: params.project.id,
          originalName: params.name,
          seriesParentId: null,
          seriesOrder: null,
          assignment: null,
          datasetId: params.datasetId,
          isSeries: false,
          fileBySeriesParentId: null,
          processingStatus: ProcessingStatus["PENDING"],
          outputGroupFiles: {
            __typename: "OutputGroupFilesConnection",
            nodes: [],
          },
          recordingGroupFiles: {
            __typename: "RecordingGroupFilesConnection",
            nodes: [],
          },
          user: {
            __typename: "ApplicationUser",
            id: currentUser.id,
          },
          dataset:
            params.datasetId !== null
              ? {
                  __typename: "Dataset",
                  id: params.datasetId,
                }
              : null,
          seriesFiles: {
            __typename: "FilesConnection",
            nodes: [],
          },
          fileActivities: {
            __typename: "FileActivitiesConnection",
            nodes: [],
          },
          fileMetadata: {
            __typename: "FileMetadataConnection",
            nodes: [],
          },
          taskSources: {
            __typename: "TaskSourcesConnection",
            nodes: [],
          },
          tenant: {
            __typename: "Tenant",
            id: currentTenant.id,
          },
          project: {
            __typename: "Project",
            id: params.project.id,
          },
          fileProcessingNotifications: {
            __typename: "FileProcessingNotificationsConnection",
            nodes: [],
          },
        },
      });

      // Add the new DRS object to the project cache
      updateCacheFragment({
        __typename: "Project",
        id: params.project.id,
        update: (data) => {
          const newData = cloneDeep(data);
          if (newData?.activeFiles === undefined) {
            newData.activeFiles = {
              __typename: "FilesConnection",
              nodes: [],
            };
          }
          newData.activeFiles.nodes.push({
            __typename: "File",
            id: params.id,
          });

          if (newData?.files === undefined) {
            newData.files = {
              __typename: "FilesConnection",
              nodes: [],
            };
          }
          newData.files.nodes.push({
            __typename: "File",
            id: params.id,
          });

          return newData;
        },
      });
    },
    [currentTenant.id, currentUser.id],
  );

  const enqueueUpload = useCallback(
    async (params: EnqueueUploadParams) => {
      const blob = params.blob;

      const { fileType, fileFormat } = await identifyFile(blob);

      const processingStatus =
        fileType === FILE_TYPES_BY_KEY["unknown"].id
          ? // File type is unknown meaning it's not processable so we tell the backend to skip processing
            ProcessingStatus["SKIPPED"]
          : ProcessingStatus["PENDING"];

      updateCacheFragment({
        __typename: "File",
        id: params.id,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.fileType = fileType;
          newData.fileFormat = fileFormat;
          newData.processingStatus = processingStatus;
          return newData;
        },
      });

      const { file } = await createFile({
        projectId: params.project.id,
        id: params.id,
        name: params.name,
        size: params.blob.size,
        datasetId: params.datasetId,
        tenantId: params.tenantId,
        fileType,
        fileFormat,
        processingStatus,
      });

      return enqueueFile({
        drsFile: file,
        blob: params.blob,
        project: params.project,
        tenantId: file.tenantId,
      });
    },
    [createFile, enqueueFile],
  );

  return { writeCache, enqueueUpload };
};
