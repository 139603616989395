import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFormRow,
  EuiIcon,
} from "@inscopix/ideas-eui";
import { useMemo } from "react";
import { FILE_TYPES_BY_ID, FILE_TYPES_BY_KEY, FileType } from "types/FileTypes";
import { isDefined } from "utils/isDefined";

const options: EuiComboBoxOptionOption<FileType["id"]>[] = [
  // TODO FILE TYPE MIGRATION
  { ...FILE_TYPES_BY_KEY["unknown"], name: "Any" },
  // common types ordered by most expected to be used
  FILE_TYPES_BY_KEY["miniscope_movie"],
  FILE_TYPES_BY_KEY["nvision_movie"],
  FILE_TYPES_BY_KEY["movie"],
  FILE_TYPES_BY_KEY["cell_set"],
  FILE_TYPES_BY_KEY["vessel_set"],
  FILE_TYPES_BY_KEY["neural_events"],
  FILE_TYPES_BY_KEY["timestamp_events"],
  FILE_TYPES_BY_KEY["gpio_data"],
  FILE_TYPES_BY_KEY["imu_data"],
  FILE_TYPES_BY_KEY["miniscope_image"],
  FILE_TYPES_BY_KEY["image"],
  FILE_TYPES_BY_KEY["event_aligned_neural_data"],
  FILE_TYPES_BY_KEY["experiment_annotations"],
  FILE_TYPES_BY_KEY["nwb_data"],
  // new file types added for manual identification - less expected to be used
  // ordered alphabetically
  FILE_TYPES_BY_KEY["behavior_annotations"],
  FILE_TYPES_BY_KEY["bruker2p_movie"],
  FILE_TYPES_BY_KEY["bundle"],
  FILE_TYPES_BY_KEY["calcium_image_qc_data"],
  FILE_TYPES_BY_KEY["config"],
  FILE_TYPES_BY_KEY["dlc_model"],
  FILE_TYPES_BY_KEY["dlc_model_predictions_data"],
  FILE_TYPES_BY_KEY["combined_correlation_data"],
  FILE_TYPES_BY_KEY["combined_peri_event_statistics"],
  FILE_TYPES_BY_KEY["combined_peri_event_traces"],
  FILE_TYPES_BY_KEY["combined_population_activity_data"],
  FILE_TYPES_BY_KEY["correlation_data"],
  FILE_TYPES_BY_KEY["correlation_comparison_data"],
  FILE_TYPES_BY_KEY["locomotion_comparison_data"],
  FILE_TYPES_BY_KEY["movie_zones"],
  FILE_TYPES_BY_KEY["modulation_data"],
  FILE_TYPES_BY_KEY["peri_event_comparison_data"],
  FILE_TYPES_BY_KEY["population_activity_comparison_data"],
  FILE_TYPES_BY_KEY["statistics"],
  FILE_TYPES_BY_KEY["suite2p_data"],
  FILE_TYPES_BY_KEY["zone_occupancy_analysis"],
].map((fileType) => ({
  value: fileType.id,
  label: fileType.name,
  prepend: <EuiIcon type={fileType.icon} />,
}));

interface FieldFileTypeProps {
  autoFocus?: boolean;
  value: FileType["id"] | undefined;
  onChange: (value: FileType | undefined) => void;
}

export const FieldFileType = ({
  autoFocus = false,
  value,
  onChange,
}: FieldFileTypeProps) => {
  const handleChange = (options: EuiComboBoxOptionOption<FileType["id"]>[]) => {
    const newValue = options[0]?.value;
    if (isDefined(newValue) && newValue !== value) {
      const fileType = FILE_TYPES_BY_ID[newValue];
      onChange(fileType);
    } else {
      onChange(undefined);
    }
  };

  const selectedOptions = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return isDefined(option) ? [option] : [];
  }, [value]);

  return (
    <EuiFormRow
      label="Data type"
      helpText="Choosing a data type restricts the column to storing a specific kind of data. This keeps your table organized and allows data to be used in analyses."
    >
      <EuiComboBox<FileType["id"]>
        singleSelection={{ asPlainText: true }}
        options={options}
        selectedOptions={selectedOptions}
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder="Select a file type..."
        rowHeight={40}
      />
    </EuiFormRow>
  );
};
