import { Fragment, useState } from "react";
import {
  EuiCheckableCard,
  EuiCheckbox,
  EuiConfirmModal,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { getDrsFileModifyPermissionByDrsFileAndAction } from "types/DrsFileModifyPermissions";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { CallOutError } from "components/CallOutError/CallOutError";
import { captureException } from "@sentry/react";

type ModalOptions = "DATA_DELETE" | "DELETE";
type ModalOptionLabelMap = Record<ModalOptions, string>;
type ModalOptionsBodyMap = Record<ModalOptions, React.ReactNode>;

const modalOptionLabelMap: ModalOptionLabelMap = {
  DATA_DELETE: "Delete Data",
  DELETE: "Delete Data and History",
} as const;

export type ModalDeleteDrsFileGenericProps = {
  file: Pick<
    DrsFile,
    | "id"
    | "status"
    | "name"
    | "source"
    | "isSeries"
    | "projectId"
    | "seriesParentId"
    | "processingStatus"
  >;
  onClose: () => void;
};

export const ModalDeleteDrsFileGeneric = ({
  file,
  onClose,
}: ModalDeleteDrsFileGenericProps) => {
  // Determine if the file can be data deleted
  const isDataDeletePermitted = getDrsFileModifyPermissionByDrsFileAndAction(
    file,
    "DATA_DELETE",
  ).isPermitted;

  // Determine if the file can be hard deleted
  const isHardDeletePermitted = getDrsFileModifyPermissionByDrsFileAndAction(
    file,
    "DELETE",
  ).isPermitted;

  // Create a list of options based on the permissions
  const options = [
    ...(isDataDeletePermitted ? ["DATA_DELETE" as const] : []),
    ...(isHardDeletePermitted ? ["DELETE" as const] : []),
  ];

  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const deleteFile = useDataTableContext((s) => s.deleteFile);
  const [isConfirmDeleteChecked, setIsConfirmDeleteChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState<ModalOptions>(
    isDataDeletePermitted ? "DATA_DELETE" : "DELETE",
  );

  // This should never happen but it's worth the safety check
  if (options.length === 0) {
    captureException("Undeletable file received by ModalDeleteDrsFileGeneric");
    return <CallOutError />;
  }

  const onSubmit = async () => {
    setIsLoading(true);

    const { error } = await deleteFile(
      file.id,
      checked === "DATA_DELETE" ? "data_delete" : "delete",
    );

    if (error === undefined) {
      addUtilityToastSuccess("File deleted");
    } else {
      addUtilityToastFailure("Failed to delete file");
    }

    setSelectedFileId(undefined);
    setIsLoading(false);
    onClose();
  };

  const modalOptionsBodyMap: ModalOptionsBodyMap = {
    DATA_DELETE: (
      <EuiText>
        Remove this data from storage. Metadata, previews, and task/dataset
        history will be preserved, but the file will become permanently
        unavailable and will not count towards active or archived storage.
      </EuiText>
    ),
    DELETE: (
      <>
        <EuiText>
          <p>
            Completely remove the file and any associated metadata, previews,
            and history. This is only recommended in cases of sensitive data
            that must be fully removed.
          </p>
          <p>
            <strong>
              This will retroactively remove this data from any saved dataset
              versions and existing analyses.
            </strong>
          </p>
        </EuiText>
        <EuiSpacer size="l" />
        <EuiCheckbox
          id="CONFIRM_DELETE"
          label={
            <EuiText>
              <strong>
                I understand this action will alter historical records and
                cannot be undone.
              </strong>
            </EuiText>
          }
          checked={isConfirmDeleteChecked}
          onChange={(e) => setIsConfirmDeleteChecked(e.target.checked)}
          disabled={checked !== "DELETE"}
        />
      </>
    ),
  };

  return (
    <EuiConfirmModal
      title="Delete File"
      cancelButtonText="Cancel"
      confirmButtonText="Confirm"
      onCancel={onClose}
      onConfirm={() => void onSubmit()}
      buttonColor="danger"
      defaultFocusedButton="cancel"
      confirmButtonDisabled={checked === "DELETE" && !isConfirmDeleteChecked}
      isLoading={isLoading}
    >
      <>
        {options.map((option, i, arr) => (
          <Fragment key={option}>
            <EuiCheckableCard
              label={modalOptionLabelMap[option]}
              id={option}
              checked={checked === option}
              onChange={() => {
                setIsConfirmDeleteChecked(false);
                setChecked(option);
              }}
              hasShadow={false}
            >
              {checked === option ? modalOptionsBodyMap[option] : null}
            </EuiCheckableCard>
            {i !== arr.length - 1 && <EuiSpacer size="s" />}
          </Fragment>
        ))}
      </>
    </EuiConfirmModal>
  );
};
