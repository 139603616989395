import { useCallback, useEffect } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { FlyoutDrsFileInfoGeneric } from "components/FlyoutDrsFileInfo/FlyoutDrsFileInfoGeneric";

interface DataTableFlyoutRightProps {
  toggleFlyout: (isOpen: boolean) => void;
}

/** Component that renders a right-side flyout for a data table */
export const DataTableFlyoutRight = ({
  toggleFlyout,
}: DataTableFlyoutRightProps) => {
  const setSelectedFileId = useDataTableContext((s) => s.setSelectedFileId);
  const selectedFile = useDataTableContext((s) => {
    return s.selectedFileId !== undefined
      ? s.fileMap[s.selectedFileId]
      : undefined;
  });

  const isFlyoutOpen =
    selectedFile !== undefined && !(selectedFile instanceof Error);

  useEffect(() => {
    toggleFlyout(isFlyoutOpen);
  }, [isFlyoutOpen, toggleFlyout]);

  const handleClose = useCallback(() => {
    setSelectedFileId(undefined);
  }, [setSelectedFileId]);

  if (!isFlyoutOpen) {
    return null;
  }

  return (
    <FlyoutDrsFileInfoGeneric drsFile={selectedFile} onClose={handleClose} />
  );
};
